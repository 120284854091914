import React, { useState, useEffect } from "react";
import "./Cpg.css";
import "./Financial.css";
import finance from "../../src/videos/Finance.mp4";
import footerland from "../../src/videos/footerland.mp4";
import Footer from "../products/Footer";

function Cpg() {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // const sections = document.querySelectorAll("section");
      console.log("sections", window.scrollY);
      // sections.forEach((section) => {
      //   const sectionTop = section.offsetTop;
      //   const sectionHeight = section.clientHeight;

      //   if (window.scrollY >= sectionTop - sectionHeight / 2) {

      //     setActiveLink(section.getAttribute("id"));
      //   }
      // });
      const position = window.scrollY;
      console.log("position", position);
      if (position < 5000) {
        setActiveLink("rgm");
      } else if (position >= 5000 && position < 5000 + 580) {
        setActiveLink("tpe");
      } else if (position >= 5000 + 580 && position < 5000 + 580 * 2) {
        setActiveLink("mlworks-cpg");
      } else if (position >= 5000 + 580 * 2 && position < 5000 + 580 * 3) {
        setActiveLink("scct-cpg");
      } else {
        setActiveLink("analytics");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">CPG</h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="indrdt-section4">
        <div className="financial-height">
          <div className="gradient-layer-finance">
            <video className="indrdt1-height" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/CPG.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="indrdt-1-data container">
          <h1 className="finance-4-h1">CPG</h1>
          <p className="indrdt-4-p  col-md-8">
            Explore a purposeful career journey within a collaborative setting.
          </p>
        </div>
      </section>

      <section>
        <div className="finance-bg">
          <div className="container width-increase">
            <div className="row financial-padding">
              <div className="col-md-12 d-flex ">
                <div className="col-md-3">
                  <h3 className="percentage-number">30%</h3>
                  <p className="finance-paragraph">
                    improvement in demand forecasting accuracy, enabling CPG
                    companies to optimize inventory levels and reduce stockouts.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">35%</h3>
                  <p className="finance-paragraph">
                    increase in supply chain efficiency by optimizing
                    transportation routes, reducing lead times, and improving
                    inventory management.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">15%</h3>
                  <p className="finance-paragraph">
                    increase in successful product launches by providing
                    insights into consumer preferences, market trends, and
                    competitor analysis.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">20%</h3>
                  <p className="finance-paragraph">
                    through optimized production processes, reduced waste, and
                    improved resource allocation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">CPG Expertise</h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft-embark">
                    Leverage CPG analytics and AI-driven insight engines to
                    propel consumer brands towards exponential value creation.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="third-section"
        style={{ background: "rgba(236, 236, 236, 0.93)" }}
      >
        <div className="observe-why-landing">
          <div>
            <p className="banking-Ai">
              tHE dATAOBSERVE ADVANTAGE <br></br>IN CGP
            </p>
          </div>
          <div className="container">
            <div className="row revolutioN-banking">
              <div className="pro-data-finance-one">
                <div className="col-md-1 new-text-travel">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-cpg"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Value attained faster</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      DataObserve accelerates value attainment in CPG with
                      rapidity and precision.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pro-data-cpg-two">
                <div className="col-md-1 new-text-cpg-two">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-cpg-01"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Sustained revenue growth</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      DataObserve drives sustained revenue growth in the CPG
                      sector through insightful analytics and strategic
                      initiatives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pro-data-cpg-three">
                <div className="col-md-1">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-cpg-01"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Future growth pockets identified</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      DataObserve identifies future growth pockets within the
                      CPG industry, paving the way for strategic expansion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-finance-color">
        <div>
          <div className="container SERVICE-Retail">
            <h4 className="financE-service">Services</h4>
            <p className="container col-md-7 enterprises-paragraph">
              Enabling CPG enterprises with AI and analytics throughout the
              entirety of the customer lifecycle journey.
            </p>
          </div>
          <div className="container">
            <div className="row service-life-finance">
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-engineering.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Data Engineering</h4>
                  <p className="data-sector-paragraph-01">
                    DataObserve empowers the financial services sector with
                    extensive data engineering and modernization services,
                    enabling insights creation and adoption across all
                    decision-making levels. Our solutions prioritize cost
                    reduction, avoidance of technical debt, and long-term
                    sustainability.
                  </p>
                </div>
              </div>

              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-migration2.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Data Migration</h4>
                  <p className="data-sector-paragraph-01">
                    Our data migration accelerators assist enterprises in
                    unleashing the potential of their data and facilitate
                    migration to a modern, cloud-native architecture.
                    DataObserve boasts a proven track record of supporting
                    global travel and hospitality businesses in large-scale data
                    migration initiatives, accelerating modernization ROI
                    through partnerships with leading hyperscalers, data, and AI
                    companies.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/humanizing.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">huMANIZING ai</h4>
                  <p className="data-sector-paragraph-01">
                    We curate specific datasets for out-of-the-box solutions to
                    swiftly gain insights and a deeper understanding of our
                    clients' customers beyond what traditional platform
                    reporting offers.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/closing.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Closing the last mile in cpg analytics
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Our platform modernization and implementation services aid
                    in migrating your data from legacy platforms to a modern
                    technology stack, reducing costs and significantly
                    streamlining processes. This enables the development of
                    self-service capabilities and facilitates data-driven
                    decision-making consistently.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Solutions-last-div">
          <div className="container SERVICE-Retail">
            <h4 className="financE-service-01">SOLUTIONS</h4>
            <p className="container col-md-8 enterprises-paragraph-01">
              AI services that scale and pave the way for commercial advantage
              in the CPG sector.
            </p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="sidebar-Top-ONE">
                  <ul className="sideBar-Scroll-MENU">
                    <li>
                      <a
                        href="#rgm"
                        className={
                          activeLink === "rgm" ? "nav-link active" : "nav-link"
                        }
                      >
                        DO RGM
                      </a>
                    </li>

                    <li>
                      <a
                        href="#tpe"
                        className={
                          activeLink === "tpe" ? "nav-link active" : "nav-link"
                        }
                      >
                        DO Tpe
                      </a>
                    </li>
                    <li>
                      <a
                        href="#mlworks-cpg"
                        className={
                          activeLink === "mlworks-cpg"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO MLWORKS
                      </a>
                    </li>
                    <li>
                      <a
                        href="#scct-cpg"
                        className={
                          activeLink === "scct-cpg"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO SCCT
                      </a>
                    </li>
                    <li>
                      <a
                        href="#analytics"
                        className={
                          activeLink === "analytics"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO Sustainable Analytics
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <section id="rgm">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO RGM</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DataObserve's Revenue Growth Management enables
                          sustainable revenue growth across products, consumer
                          channels, and geographies. Gain a competitive edge
                          with real-time CPG data insights on pricing,
                          assortment, and distribution.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO RGM</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h3>adaptive pricing strategy</h3>
                          <h3>optimized DISTRIBUTION STRATEGY</h3>
                          <h3>rESPONSIVE ASSORTMTNT STRATEGY</h3>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DataObserve's Revenue Growth Management enables
                            sustainable revenue growth across products, consumer
                            channels, and geographies. Gain a competitive edge
                            with real-time CPG data insights on pricing,
                            assortment, and distribution.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="tpe">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO TPE</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DataObserve's Trade Promotion Effectiveness optimizes
                          design and delivery at a granular level, preserving
                          your brand equity, fostering sales growth, and
                          maintaining channel partnerships.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO TPE</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h3>Current scenario analysis & benchmarking</h3>
                          <h3>
                            Active & past trade promotion effectiveness analysis
                          </h3>
                          <h3>Trade promotion simulation</h3>
                          <h3>
                            Effectiveness insights to trade promotion action
                          </h3>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DataObserve's Trade Promotion Effectiveness
                            optimizes design and delivery at a granular level,
                            preserving your brand equity, fostering sales
                            growth, and maintaining channel partnerships.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="mlworks-cpg">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO MLWORKS</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          Leveraging predictive analytics and modern tools such
                          as geospatial clustering and rule-based AI, fraud
                          prevention aids financial services entities in
                          seamlessly detecting and preventing fraud across
                          various domains including credit cards, checking
                          accounts, insurance, and beyond.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO MLWORKS</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            Leveraging predictive analytics and modern tools
                            such as geospatial clustering and rule-based AI,
                            fraud prevention aids financial services entities in
                            seamlessly detecting and preventing fraud across
                            various domains including credit cards, checking
                            accounts, insurance, and beyond.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="scct-cpg">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360"> DO SCCT</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          The DataObserve Supply Chain Control Tower offers
                          real-time visibility across the supply chain and
                          utilizes predictive, prescriptive, and cognitive
                          models to continuously optimize your end-to-end supply
                          chain operations.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO SCCT</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Real-time visualization and diagnostics</h4>
                          <h4>Scenario planning</h4>
                          <h4>Prescriptive and predictive analytics</h4>
                          <h4>Real-time event alerts</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            The DataObserve Supply Chain Control Tower offers
                            real-time visibility across the supply chain and
                            utilizes predictive, prescriptive, and cognitive
                            models to continuously optimize your end-to-end
                            supply chain operations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="analytics">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">
                          DO sustainable ANALYTICS
                        </h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DataObserve Sustainability Analytics enables
                          continuous monitoring, measurement, and cataloging of
                          the cost impact and performance of your sustainability
                          initiatives.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">
                            DO sustainable ANALYTICS
                          </h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>ESG strategy and performance management</h4>
                          <h4>Robust data management</h4>
                          <h4>Data modeling and reporting</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DataObserve Sustainability Analytics enables
                            continuous monitoring, measurement, and cataloging
                            of the cost impact and performance of your
                            sustainability initiatives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-finance-02">
            <div className="gradient-layer-finance-01">
              <img
                src="./images/truck.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-7 ai-ana-home">
                    Enhanced revenue and trade promotions decision-making for a
                    consumer goods industry leader via a data lake solution.
                  </p>
                  <p className="container col-md-7 unique-home">
                    We built and delivered a robust and scalable sales business
                    data lake on Azure/Snowflake to support playbooks on RGM and
                    trade promotions management. This delivered a range of
                    benefits.
                  </p>
                  <a
                    href="./industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="Homepage-Contact-Us-3">
                      Contact us
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                Proactively spot growth opportunities and monetize them through
                CPG data analytics
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking">
                With our top-tier CPG practice, we prioritize agility and offer
                comprehensive visibility across your value chain. Harness our
                profound domain knowledge and AI engineering expertise in demand
                forecasting, revenue optimization, trade promotions, supply
                chain planning, and digital customer journey to drive impactful
                results.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Data Consolidation Challenges in Value Chain
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Due to the fragmentation of CPG data across disjointed
                  business functions, generating substantial impact becomes
                  costly and unscalable.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <h4 className="higher-finance">
                  A deluge of data from heterogeneous markets
                </h4>
                <p className="col-md-12 speed-paragraph">
                  This complexity arises when processing numerous localizations
                  to implement insight-driven strategies at more granular
                  levels.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Limited actionable insights for future growth
                </h4>
                <p className="col-md-10 speed-paragraph">
                  This complexity hinders the upgrade and sustainability of core
                  commercial capabilities and the measurement of impact.
                </p>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </section>

      <section id="video" className="landing-page-what-we-do-02">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
            {/* <div>
              <p className="ocss-2">OUR CASE STUDIES</p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">TRAVEL & HOSPITALITY</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/travel" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">IndustriALs</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/industrial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">Life Sciences</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/lifescience" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
            {/* <video className="" autoPlay loop muted>
              <source src={footerland} type="video/mp4" />
            </video> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Cpg;
