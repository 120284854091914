import React from "react";
import "./Entertain.css";
import Footer from "../products/Footer";
import footerland from "../../src/videos/footerland.mp4";

const Entertain = () => {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">
                    MEDIA & ENTERTAINMENT
                  </h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 1 */}
      <section className="ind-lifesub-sec-1">
        <div className="sec-01-main">
          <div className="Life-01-main">
            <video className="dop-1" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/media.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="life-science-sub container">
          {/* <svg
            className="logo-svg"
            width="75"
            height="30"
            viewBox="0 0 75 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_27_6718)">
              <circle cx="12.3265" cy="13.807" r="12.4398" fill="#ECECEC" />
              <circle cx="39.506" cy="14.2677" r="12.9005" fill="#ECECEC" />
            </g>
            <defs>
              <clipPath id="clip0_27_6718">
                <rect
                  width="42.3874"
                  height="25.801"
                  fill="white"
                  transform="translate(10.9414 1.36719)"
                />
              </clipPath>
            </defs>
          </svg> */}

          <h1 className="life-science-sec-1">MEDIA & ENTERTAINMENT</h1>
          <p className="sec-1-para">
            Explore a purposeful career journey within a collaborative setting.
          </p>
        </div>
      </section>

      {/* section 2 */}
      <section className="percentages-sec-2">
        <div className="sec-2-main container">
          <div className="main-para-sec-2 row">
            <div className="col-md-3">
              <h1 className="percentages">35%</h1>
              <p className="sec-2-para">
                increase in content recommendation accuracy, enhancing user
                engagement and satisfaction
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">30%</h1>
              <p className="sec-2-para">
                improvement in understanding audience preferences and behavior,
                enabling targeted content creation and distribution.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">45%</h1>
              <p className="sec-2-para">
                accelerate content delivery speeds, reducing buffering times and
                improving overall user experience.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">45%</h1>
              <p className="sec-2-para">
                accelerate content delivery speeds, reducing buffering times and
                improving overall user experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* section 3 */}
      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-enter-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance-enter">
                    MEDIA & ENTERTAINMENT <br></br>
                  </h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft-enter">
                    Embrace the paradigm shift in the media and entertainment
                    industry with lightning-fast distribution and extensive
                    storage capacity through cloud migration. Collaborate with
                    DataObserve to enhance the agility, flexibility, and
                    scalability of your content supply chain. Request a demo
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 4 */}
      <section className="life-science-exp-sec-4">
        <div className="sec-4-hero">
          <div className="why-l-s col-md-12">
            <p className="why-d-l-s-para">
              DATAOBSERVE FOR MEDIA AND ENTERTAINMENT
            </p>

            <div className="container">
              <div className="row">
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text">
                    <img
                      src="./images/data-lake-01.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      ADVANCED DATA LAKEHOUSES FOR M&E
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Streamlined Workload Management</b>:Tailored M&E data
                        lakehouses efficiently handle massive streaming
                        workloads and unstructured customer data.
                      </li>
                      <li className="list-sub-1">
                        <b>Cloud-Big Data Synergy</b>:Integration of cloud
                        solutions and big data empowers AI to scale data
                        pipelines, boosting system performance.
                      </li>
                      <li className="list-sub-1">
                        <b>Cost-Effective Technology</b>:Advanced data
                        lakehouses and cloud-driven big data solutions reduce
                        costs, optimizing resource utilization in media and
                        entertainment.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text2">
                    <img
                      src="./images/data-lake-02.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      D2C Streaming BigData Analytics
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Optimized D2C Streaming</b>:Leverage cloud
                        capabilities for seamless D2C streaming with integrated
                        Big Data Analytics.
                      </li>
                      <li className="list-sub-1">
                        <b>Cloud-Powered Analytics Integration</b>: Explore
                        synergy as our cloud solutions and advanced analytics
                        deliver industry-leading, seamless entertainment
                        experiences.
                      </li>
                      <li className="list-sub-1">
                        <b>Empowering Content Delivery</b>: Our cloud solutions
                        and advanced analytics set new standards for immersive,
                        effortless top-tier entertainment.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data-entertain">
                  <div className="col-md-1 adv-new-text2-entertain">
                    <img
                      src="./images/data-lake-03.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">UNIFIED AUDIENCE PROFILING</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Unified Audience Profiling</b>: Easily analyze
                        extensive data with our unified system.
                      </li>
                      <li className="list-sub-1">
                        <b>Comprehensive Data Management</b>:Capture, store, and
                        manage customer data seamlessly with our Cloud partner.
                      </li>
                      <li className="list-sub-1">
                        <b>Precision in Targeted Marketing</b>:Utilize
                        interpreted data for precise and effective
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text">
                    <img
                      src="./images/data-lake-05.png"
                      className="adv-decental-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      UNLOCK THE VALUE OF MEDIA ASSETS
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Optimized Content Utilization</b>:Utilize advanced
                        analytics to create custom audience segments and
                        optimize results from your content catalog.
                      </li>
                      <li className="list-sub-1">
                        <b>Effortless Repurposing</b>:Unlock the full potential
                        of media assets through seamless content repurposing
                        with just a few clicks.
                      </li>
                      <li className="list-sub-1">
                        <b>Unlocking Media Asset Potential</b>:Empower your
                        content strategy with advanced analytics, efficiently
                        segmenting audiences and effortlessly repurposing
                        content for enhanced results.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text">
                    <img
                      src="./images/data-lake-06.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">AI/ML MODELS FOR PRODUCTION</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Integrated AI in Production</b>: Seamlessly integrate
                        AI models using AWS or Azure for enhanced media
                        production efficiency.
                      </li>
                      <li className="list-sub-1">
                        <b>Big Data Analytics Fusion</b>:Combine big data
                        analytics with production to ensure personalized user
                        experiences and enforce data quality standards.
                      </li>
                      <li className="list-sub-1">
                        <b>Enhanced User Experiences</b>:Integrate AI and
                        analytics for personalized user experiences and maintain
                        data quality throughout media production.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text-1">
                    <img
                      src="./images/data-lake-07.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">DO FOR M&E</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Content Production</b>: Streamline content creation
                        with innovative production processes and technologies.
                      </li>
                      <li className="list-sub-1">
                        <b>Media Supply Chain Management</b>:Optimize content
                        distribution and delivery through efficient supply chain
                        management.
                      </li>
                      <li className="list-sub-1">
                        <b>Direct-to-Consumer Streaming</b>:Deliver seamless
                        entertainment experiences directly to consumers with
                        advanced streaming solutions.
                      </li>
                      <li className="list-sub-1">
                        <b>Advanced Data Analytics</b>:Harness the power of data
                        analytics for insightful and informed decision-making in
                        various domains.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 6 */}

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-Gaming">
            <div className="middle-building">
              <img
                src="./images/media-enter-08.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-11 ai-ana-home">
                    Elevating benchmarks within the media and entertainment
                    sector by seamlessly integrating artificial intelligence and
                    analytics.
                  </p>
                  <p className="container col-md-11 unique-home">
                    In the realm of media and entertainment, every sector poses
                    distinctive challenges, often lacking predefined strategies
                    for numerous pressing issues. Resolving these challenges
                    demands a nuanced approach, striking a balance between
                    following best practices and fostering innovative, ground-up
                    thinking.
                  </p>
                  <a style={{ textDecoration: "none" }}>
                    <button
                      className="Homepage-Contact-Us-3"
                      onClick={handleContactButtonClick}
                    >
                      Contact
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding-Life">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                Implementing advanced data management solutions to safeguard the
                future of the media and entertainment industry.
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking-Life">
                In the media and entertainment sector, adherence to the FAIR
                Data Principles is paramount for effective data management.
                These principles center around ensuring that research data is
                findable, accessible, interoperable, and reusable, promoting
                efficient data utilization. Our customers prioritize data
                governance, trustworthiness, traceability, and quality, crucial
                elements for successful self-service analytics and AI
                initiatives.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                {/* <img
                                    src="./images/findable.png"
                                    className="findable"
                                    alt="star"
                                /> */}
                <h4 className="speed-finance">Metadata-Rich Data</h4>
                <p className="col-md-10 speed-paragraph">
                  Media and entertainment data is distinguished by its
                  comprehensive metadata and carries a unique and enduring
                  identifier.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                {/* <img
                                    src="./images/findable.png"
                                    className="accessable"
                                    alt="star"
                                /> */}
                <h4 className="speed-finance">Accessible</h4>
                <p className="col-md-12 speed-paragraph">
                  In media, data and metadata are intelligible to both humans
                  and machines, accessible through clear protocols.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                {/* <img
                                    src="./images/interpolable2.png"
                                    className="findable"
                                    alt="star"
                                /> */}
                <h4 className="speed-finance">Knowledge Representation</h4>
                <p className="col-md-10 speed-paragraph">
                  In media, data and metadata employ formal knowledge methods
                  for reusability.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                {/* <img
                                    src="./images/reusable2.png"
                                    className="accessable"
                                    alt="star"
                                /> */}
                <h4 className="speed-finance">Detailed</h4>
                <p className="col-md-12 speed-paragraph">
                  In the context of media and entertainment, metadata precisely
                  outlines the origin and terms of data usage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 7 */}

      {/* SECTION 8 */}
      <section id="video" className="landing-page-what-we-do-02-finance">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">Life Science</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/lifescience" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">GAMING</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/gamingnew" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">fINANCE</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/financial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Entertain;
