import React, { useState, useEffect } from "react";
import "./Industrial.css";
import "./Financial.css";
import finance from "../../src/videos/Finance.mp4";
import footerland from "../../src/videos/footerland.mp4";
import Footer from "../products/Footer";

function Industrial() {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // const sections = document.querySelectorAll("section");
      console.log("sections", window.scrollY);
      // sections.forEach((section) => {
      //   const sectionTop = section.offsetTop;
      //   const sectionHeight = section.clientHeight;

      //   if (window.scrollY >= sectionTop - sectionHeight / 2) {

      //     setActiveLink(section.getAttribute("id"));
      //   }
      // });
      const position = window.scrollY;
      console.log("position", position);
      if (position < 7200) {
        setActiveLink("docx");
      } else if (position >= 7200 && position < 7200 + 530) {
        setActiveLink("custoer");
      } else if (position >= 7200 + 530 && position < 7200 + 530 * 2) {
        setActiveLink("Learn");
      } else if (position >= 7200 + 530 * 2 && position < 7200 + 530 * 3) {
        setActiveLink("mlworks-travel");
        // } else {
        //   setActiveLink("tlap");
        // }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">Industries</h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="indrdt-section4">
        <div className="financial-height">
          <div className="gradient-layer-finance">
            <video className="indrdt1-height" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/mANUFACTURING.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="indrdt-1-data container">
          <h1 className="finance-4-h1">IndustriALs</h1>
          <p className="indrdt-4-p  col-md-8">
            Transition from static to intelligent manufacturing with AI-driven
            industrial analytics.
          </p>
        </div>
      </section>

      <section>
        <div className="finance-bg">
          <div className="container width-increase">
            <div className="row financial-padding">
              <div className="col-md-12 d-flex ">
                <div className="col-md-3">
                  <h3 className="percentage-number">60%</h3>
                  <p className="finance-paragraph">
                    Optimized resource utilization and energy efficiency
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">30%</h3>
                  <p className="finance-paragraph">
                    increase in operational efficiency
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">25%</h3>
                  <p className="finance-paragraph">
                    reduction in downtime due to predictive maintenance and
                    real-time monitoring
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">20%</h3>
                  <p className="finance-paragraph">
                    improvement in product quality by leveraging real-time data
                    analytics
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">Industrials Expertise</h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft-embark">
                    Harness AI as your driving force for high-speed performance
                    with manufacturing analytics, accelerating the value in your
                    production process.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="third-section"
        style={{ background: "rgba(236, 236, 236, 0.93)" }}
      >
        <div className="observe-why-landing">
          <div>
            <p className="banking-Ai">
              tHE dATAOBSERVE ADVANTAGE <br></br>IN INDUSTRIALS
            </p>
          </div>
          <div className="container">
            <div className="row revolutioN-banking">
              <div className="pro-data-finance-one">
                <div className="col-md-1 new-text-industrial-01">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-industrial-01"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-industrial">
                  <h5 className="proven">
                    Build resilience and drive accurate demand forecast
                  </h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Leverage the DataObserve advantage in Industrials to
                      foster resilience and enhance demand forecast accuracy.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pro-data-industrial-two">
                <div className="col-md-1 new-text-industrial-02">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-industrial-02"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-industrial-01">
                  <h5 className="proven">
                    Enhance predictabilityand increase ROI
                  </h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Leverage the DataObserve advantage in Industrials to
                      enhance predictability and maximize ROI.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pro-data-industrial-three">
                <div className="col-md-1">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME-industrial-03"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-industrial-02">
                  <h5 className="proven">
                    Maximize efficiency and productivity
                  </h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Leverage the DataObserve advantage in Industrials to
                      maximize efficiency and productivity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-finance-color">
        <div>
          <div className="container SERVICE-Retail">
            <h4 className="financE-service">Services</h4>
            <p className="container col-md-7 enterprises-paragraph">
              Enhance production quality and efficiency through AI-driven
              decision-making, controlling costs, improving productivity, and
              enhancing margins.
            </p>
          </div>
          <div className="container">
            <div className="row service-life-finance">
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/smart2.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Smart manufacturing and supply chain services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Enhance the long-term efficiency of your machines and
                    processes with actionable intelligence through smart
                    manufacturing and supply chain services. Utilize intelligent
                    demand forecasting and planning for labor, warehouse,
                    logistics, and dynamic production to reduce risks and make
                    informed decisions. Increase production capacity and
                    equipment lifespan while reducing operational costs through
                    predictive maintenance and quality optimization.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-engineering2.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Data engineering offerings
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Our data engineering solutions address gaps in your
                    production process by integrating end-to-end analytics for a
                    seamless user experience. Tailored to your needs, we guide
                    you from data ingestion to actionable insights and impactful
                    outcomes using the appropriate ML models. Easily
                    operationalize insights throughout your industrial value
                    chain with our AI/ML-based digital accelerators and
                    strategic partnerships, scaling them rapidly across your
                    business.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-migration3.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Data Migration</h4>
                  <p className="data-sector-paragraph-01">
                    Our Data migration accelerators assist enterprises in
                    unleashing the potential of their data and transitioning to
                    a modern, cloud-native architecture. With a proven track
                    record, DataObserve supports global manufacturers in
                    large-scale data migration initiatives, accelerating
                    modernization ROI through partnerships with leading
                    hyperscalers, data, and AI companies. Our services and
                    accelerators facilitate 50% faster migration, aiding in
                    achieving modernization goals such as enhanced insights,
                    increased productivity, and self-service capabilities.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/customer-analytics.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Customer analytics and experience services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Customer analytics and experience services provide crucial
                    insights necessary for establishing customer-centricity as a
                    core practice in your business. By eliminating data
                    inconsistencies, you gain a comprehensive view of the
                    customer, enabling tailored strategies and fostering better
                    customer relationships. Understand and fulfill customer
                    expectations through behavior modeling, journey mapping, and
                    visualizations to enhance engagement strategies.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/customer-analytics.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Optimization and productivity services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Our optimization and productivity services enable you to
                    achieve optimal outcomes for your business and customers
                    while fostering sustainable growth for the future. From
                    providing customer incentives through rebate management to
                    assessing the cost to serve, we support you in nurturing
                    partnerships while boosting sales with a unified source of
                    truth.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/sustainability.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Sustainability and CSR Services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Our Sustainability and CSR Services facilitate value
                    tracking and energy analytics, providing valuable insights
                    into consumption to proactively reduce energy costs and
                    implement efficient processes. Collaborating closely with
                    your manufacturing analytics and business teams, we help
                    build your sustainability strategy across various domains,
                    including recyclability, waste reduction, and energy
                    consumption. DataObserve empowers you with a comprehensive
                    view of your enterprise footprint, monitoring your ESG
                    scorecard, identifying high-impact opportunities, and
                    recommending strategic actions.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/sustainability.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Quality and asset management
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Quality and asset management enable you to gather contextual
                    insights for improving ROI and swiftly adapting to market
                    demands with minimal risks. Identify wastage, predict system
                    downtimes, and ensure products are defect-free and fit for
                    purpose. Ensure optimal production quality with
                    manufacturing data analytics for in-line quality prediction,
                    root cause detection, and waste control to maximize revenue.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Solutions-last-div">
          <div className="container SERVICE-Retail">
            <h4 className="financE-service-01">SOLUTIONS</h4>
            <p className="container col-md-8 enterprises-paragraph-01">
              AI services that scale, setting the course of action for
              Industrial advantage.
            </p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="sidebar-Top-ONE">
                  <ul className="sideBar-Scroll-MENU sideBar-Scroll-MENU-travel">
                    <li>
                      <a
                        href="#docx"
                        className={
                          activeLink === "docx" ? "nav-link active" : "nav-link"
                        }
                      >
                        DO RGM
                      </a>
                    </li>

                    <li>
                      <a
                        href="#custoer"
                        className={
                          activeLink === "custoer"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO Tpe
                      </a>
                    </li>
                    <li>
                      <a
                        href="#Learn"
                        className={
                          activeLink === "Learn"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO MLWORKS
                      </a>
                    </li>
                    <li>
                      <a
                        href="#mlworks-travel"
                        className={
                          activeLink === "mlworks-travel"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Trade promotion effectiveness
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <section id="docx">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO RGM</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          Utilize DataObserve Revenue Growth Management to
                          maintain revenue growth across products, consumer
                          channels, and geographies. Stay ahead of competitors
                          with real-time CPG data insights on pricing,
                          assortment, and distribution.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO RGM</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>adaptive pricing strategy</h4>
                          <h4>optimized DISTRIBUTION STRATEGY</h4>
                          <h4>rESPONSIVE ASSORTMTNT STRATEGY</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            Utilize DataObserve Revenue Growth Management to
                            maintain revenue growth across products, consumer
                            channels, and geographies. Stay ahead of competitors
                            with real-time CPG data insights on pricing,
                            assortment, and distribution.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="custoer">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO TPE</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DataObserve's Trade Promotion Effectiveness optimizes
                          design and delivery at a granular level, ensuring the
                          integrity of your brand equity, sales growth, and
                          channel partnerships.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO TPE</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h1>Current scenario analysis & benchmarking</h1>
                          <h1>
                            Active & past trade promotion effectiveness analysis
                          </h1>
                          <h1>Trade promotion simulation</h1>
                          <h1>
                            Effectiveness insights to trade promotion action
                          </h1>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DataObserve's Trade Promotion Effectiveness
                            optimizes design and delivery at a granular level,
                            ensuring the integrity of your brand equity, sales
                            growth, and channel partnerships.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="Learn">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360"> DO MLWORKS</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          Leveraging predictive analytics and modern tools such
                          as geospatial clustering and rule-based AI, fraud
                          prevention aids financial services entities in
                          seamlessly detecting and preventing fraud across
                          various domains including credit cards, checking
                          accounts, insurance, and beyond.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO MLWORKS</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            Leveraging predictive analytics and modern tools
                            such as geospatial clustering and rule-based AI,
                            fraud prevention aids financial services entities in
                            seamlessly detecting and preventing fraud across
                            various domains including credit cards, checking
                            accounts, insurance, and beyond.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="mlworks-travel">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">
                          Trade promotion effectiveness
                        </h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DataObserve Trade Promotion Effectiveness optimizes
                          design and delivery at granular level, keeping your
                          brand equity, sales growth, and channel partnerships
                          intact.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">
                            Trade promotion effectiveness
                          </h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>ESG strategy and performance management</h4>
                          <h4>Robust data management</h4>
                          <h4>Data modeling and reporting</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DataObserve Trade Promotion Effectiveness optimizes
                            design and delivery at granular level, keeping your
                            brand equity, sales growth, and channel partnerships
                            intact.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-finance-02">
            <div className="gradient-layer-finance-01">
              <img
                src="./images/tank.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-7 ai-ana-home">
                    Enhanced revenue and trade promotions decision-making for an
                    industrial leader through a data lake solution.
                  </p>
                  <p className="container col-md-7 unique-home">
                    We constructed and deployed a resilient and scalable sales
                    business data lake on Azure/Snowflake to bolster playbooks
                    on RGM and trade promotions management, resulting in a
                    variety of benefits.
                  </p>
                  <a
                    href="./industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="Homepage-Contact-Us-3">
                      Contact us
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding Industrie-spot">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                Proactively spot growth opportunities and monetize them through
                CPG data analytics
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking">
                With our top-tier Industrial practice, we emphasize agility by
                offering complete visibility along your value chain. Drive
                impact with our extensive domain knowledge and AI engineering
                expertise in demand forecasting, revenue optimization, trade
                promotions, supply chain planning, and digital customer journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Challenges in Value Chain Data Consolidation
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Due to the siloed nature of industrial data across
                  disconnected business functions, creating substantial impact
                  becomes costly and unscalable.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <h4 className="higher-finance">
                  An overflow of data from diverse markets
                </h4>
                <p className="col-md-12 speed-paragraph">
                  which complicates matters when handling numerous localizations
                  to implement insight-led strategies at more granular levels.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Restricted actionable insights hindering future growth
                </h4>
                <p className="col-md-10 speed-paragraph">
                  This complexity complicates the upgrade and sustainability of
                  core commercial capabilities and the measurement of impact.
                </p>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </section>

      <section id="video" className="landing-page-what-we-do-02">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
            {/* <div>
              <p className="ocss-2">OUR CASE STUDIES</p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">CPG</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/cpg" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">IndustriALs</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/industrial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">Life Sciences</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/lifescience" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
            {/* <video className="" autoPlay loop muted>
              <source src={footerland} type="video/mp4" />
            </video> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Industrial;
