import React from "react";
import "./Gamingnew.css";
import Footer from "../products/Footer";
import footerland from "../../src/videos/footerland.mp4";

const Gamingnew = () => {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">Gaming</h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 1 */}
      <section className="ind-lifesub-sec-1">
        <div className="sec-01-main">
          <div className="Life-01-main">
            <video className="dop-1" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/gaming.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="life-science-sub container">
          <h1 className="life-science-sec-1">Gaming</h1>
          <p className="sec-1-para">
            Explore a purposeful career journey within a collaborative setting.
          </p>
        </div>
      </section>

      {/* section 2 */}
      <section className="percentages-sec-2">
        <div className="sec-2-main container">
          <div className="main-para-sec-2 row">
            <div className="col-md-3">
              <h1 className="percentages">40%</h1>
              <p className="sec-2-para">
                through personalized gaming experiences and targeted content
                delivery.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">30%</h1>
              <p className="sec-2-para">
                improvement in game performance by optimizing server
                infrastructure, reducing latency, and minimizing downtime.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">25%</h1>
              <p className="sec-2-para">
                increase in revenue through more effective monetization
                strategies, such as in-game purchases, advertising, and
                subscription models.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">45%</h1>
              <p className="sec-2-para">
                accelerate content delivery speeds, enabling faster updates,
                patches, and downloadable content (DLC) releases.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* section 3 */}
      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">Gaming Expertise</h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft">
                    Empower customer experiences with data-driven solutions that
                    facilitate gamification.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 4 */}
      <section className="life-science-exp-sec-4">
        <div className="sec-4-hero">
          <div className="why-l-s col-md-12">
            <p className="why-d-l-s-para">
              Our Expertise in data & cloud for <br />
              Gaming and Gambling
            </p>
            <div className="container">
              <div className="row">
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text-GaminG">
                    <img
                      src="./images/gaming-mouth.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">Gaming Analytics</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Personalization & Engagement</b>: Enhance player
                        experiences with personalized, cloud-based gaming
                        analytics.
                      </li>
                      <li className="list-sub-1">
                        <b>Fraud Detection</b>: Utilize analytics to effectively
                        detect and mitigate gaming fraud, ensuring a fair and
                        secure gaming environment.
                      </li>
                      <li className="list-sub-1">
                        <b>Performance Monitoring</b>: Monitor game performance
                        continuously with analytics to maintain optimal gameplay
                        and player satisfaction.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data-gamingNew">
                  <div className="col-md-1 adv-new-text2-GAMING">
                    <img
                      src="./images/data-lake-01.png"
                      className="adv-tick-HOME-GAMING"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">Data Lakehouses for Gaming</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Data Lakehouse Integration</b>: Merge all gaming
                        analytics and big data into a single, efficient data
                        lakehouse.
                      </li>
                      <li className="list-sub-1">
                        <b> Unified Data Structure</b>: Seamlessly blend
                        structured and unstructured gaming data for deeper
                        insights.
                      </li>
                      <li className="list-sub-1">
                        <b>Real-Time Gaming Insights</b>: Gain instant, valuable
                        insights to elevate the gaming experience.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data-entertain-bigdata">
                  <div className="col-md-1 adv-new-text2-entertainandgaming">
                    <img
                      src="./images/big-gameing.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost-Gaming">
                    <h5 className="advanced">
                      Big Data with Augmented Analytics
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b> Big Data </b>revolutionizes the Gaming & Gambling
                        industry, offering advanced analytics for strategic
                        insights.
                      </li>
                      <li className="list-sub-1">
                        <b>Cloud partner</b>-driven Big Data solutions amplify
                        online gaming and gambling experiences.
                      </li>
                      <li className="list-sub-1">
                        <b> Implement Augmented Analytics</b> for automated
                        insights in gaming, elevating business intelligence and
                        outcomes
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text-Customer">
                    <img
                      src="./images/customer-game.png"
                      className="adv-decental-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      Customer Retention on Advanced BI
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b> Insightful Player Analysis</b>: Leverage Business
                        Intelligence and Analytics for quick insights into
                        player behaviors and loyalty.
                      </li>
                      <li className="list-sub-1">
                        <b> Personalized Retention Strategies</b>: Boost
                        customer retention with BI-driven personalization for
                        online players and gamblers.
                      </li>
                      <li className="list-sub-1">
                        <b> Data-Driven Player Engagement</b>: Enhance online
                        gaming and gambling experiences through tailored,
                        data-informed strategies.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data-ai-ml">
                  <div className="col-md-1 adv-new-text-Aiml">
                    <img
                      src="./images/aiml-gameing.png"
                      className="adv-tick-HOME-Model"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost-ai-ml">
                    <h5 className="advanced">AI/ML Predictive Models</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Cloud-Enhanced Gaming</b>: Boost online gaming with
                        AI and ML models on our Cloud platform.
                      </li>
                      <li className="list-sub-1">
                        <b> AI Integration</b>: Elevate gaming experiences with
                        integrated AI and ML.
                      </li>
                      <li className="list-sub-1">
                        <b> Predictive Gambling Analytics</b>: Enhance gambling
                        platforms using predictive analytics.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data-aws">
                  <div className="col-md-1 adv-new-text-1">
                    <img
                      src="./images/aws-gameing.png"
                      className="adv-tick-HOME-aws"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost-Aws">
                    <h5 className="advanced">AWS for Gaming</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Rapid Development</b>: Speed up cross-platform game
                        development with cloud technology.
                      </li>
                      <li className="list-sub-1">
                        <b>Secure Hosting</b>: Utilize partners' cloud
                        infrastructure for secure, efficient gaming.
                      </li>
                      <li className="list-sub-1">
                        <b>Global Scalability</b>: Improve global gaming
                        experiences with the cloud's scalable resources.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-Gaming">
            <div className="middle-building">
              <img
                src="./images/pinkman.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-8 ai-ana-home">
                    Elevating gaming industry standards through seamless
                    integration of AI and analytics.
                  </p>
                  <p className="container col-md-8 unique-home">
                    Leveraging our substantial expertise in cloud consulting and
                    implementation, we aspire to become a trusted partner in
                    advancing the gaming industry.
                  </p>
                  <a style={{ textDecoration: "none" }}>
                    <button
                      className="Homepage-Contact-Us-3"
                      onClick={handleContactButtonClick}
                    >
                      Contact
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding-Life">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                Cutting-edge Data Management Solutions tailored to fortify and
                future-proof the gaming industry.
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking-Life">
                Embracing the core tenets of the FAIR Data Principles, we
                prioritize making gaming industry data findable, accessible,
                interoperable, and reusable. Ensuring robust data governance,
                trustworthiness, traceability, and quality is pivotal for the
                success of self-service analytics and AI initiatives among our
                clientele.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <h4 className="speed-finance">FINDABLE</h4>
                <p className="col-md-10 speed-paragraph">
                  Optimized In the gaming industry, data is distinguished by
                  comprehensive metadata and features a unique, enduring
                  identifier for enhanced organization and efficiency.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <h4 className="speed-finance">ACCESSIBLE</h4>
                <p className="col-md-12 speed-paragraph">
                  In the gaming industry, data and its accompanying metadata are
                  designed to be easily understood by both humans and machines,
                  accessible through well-defined protocols for seamless
                  utilization.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <h4 className="speed-finance">INTEROPERABLE</h4>
                <p className="col-md-10 speed-paragraph">
                  Enhanced In the gaming industry, data and their associated
                  metadata utilize formal and accessible knowledge
                  representation methods, ensuring optimal reusability and
                  efficiency.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <h4 className="speed-finance">REUSABLE</h4>
                <p className="col-md-12 speed-paragraph">
                  Transparent In the gaming industry, metadata meticulously
                  outlines the origin and terms of use for the data, ensuring
                  clarity and accountability in information utilization.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 7 */}

      {/* SECTION 8 */}
      <section id="video" className="landing-page-what-we-do-02-finance">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">
                MEDIA & ENTERTAINMENT
              </h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/entertain" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">fINANCE</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/financial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">
                Travel & Hospitality
              </h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/travel" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Gamingnew;
