import React, { useState, useEffect } from "react";
import "./Financial.css";
import finance from "../../src/videos/Finance.mp4";
import footerland from "../../src/videos/footerland.mp4";
import Footer from "../products/Footer";

function Financial() {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // const sections = document.querySelectorAll("section");
      console.log("sections", window.scrollY);
      // sections.forEach((section) => {
      //   const sectionTop = section.offsetTop;
      //   const sectionHeight = section.clientHeight;

      //   if (window.scrollY >= sectionTop - sectionHeight / 2) {

      //     setActiveLink(section.getAttribute("id"));
      //   }
      // });
      const position = window.scrollY;
      console.log("position", position);
      if (position < 5320) {
        setActiveLink("insights");
      } else if (position >= 5320 && position < 5320 + 520) {
        setActiveLink("explorer");
      } else if (position >= 5320 + 520 && position < 5320 + 520 * 2) {
        setActiveLink("mlworks");
      } else if (position >= 5320 + 520 * 2 && position < 5320 + 520 * 3) {
        setActiveLink("feedback");
      } else {
        setActiveLink("tlap");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">Financial Services</h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="indrdt-section4">
        <div className="financial-height">
          <div className="gradient-layer-finance">
            <video className="indrdt1-height" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/Financ.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="indrdt-1-data container">
          <h1 className="finance-4-h1">Financial Services</h1>
          <p className="indrdt-4-p  col-md-8">
            Utilize data to extract customized insights, fostering significant
            outcomes and bolstering financial performance within the financial
            services industry.
          </p>
        </div>
      </section>

      <section>
        <div className="finance-bg">
          <div className="container width-increase">
            <div className="row financial-padding">
              <div className="col-md-12 d-flex ">
                <div className="col-md-3">
                  <h3 className="percentage-number">80%</h3>
                  <p className="finance-paragraph">
                    accelerate data processing speeds, enabling financial
                    institutions to handle large volumes of data in real-time.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">40%</h3>
                  <p className="finance-paragraph">
                    reduce security breaches, through advanced encryption
                    methods, access controls, and anomaly detection systems.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">30%</h3>
                  <p className="finance-paragraph">
                    cost savings, optimizing data storage, reducing
                    infrastructure complexity, and minimizing manual data
                    processing efforts.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">35%</h3>
                  <p className="finance-paragraph">
                    improve risk management practices and reduce the likelihood
                    of financial losses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">Financial Service</h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft">
                    Crafting actionable insights and yielding concrete outcomes
                    within the financial services sector.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="third-section"
        style={{ background: "rgba(236, 236, 236, 0.93)" }}
      >
        <div className="observe-why-landing">
          <div>
            <p className="banking-Ai">
              Revolutionizing Banking Through <br /> AI-Driven Modernization
            </p>
          </div>
          <div className="container">
            <div className="row revolutioN-banking">
              <div className="pro-data-finance-one">
                <div className="col-md-1 new-text-finance">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-finance">
                  <h5 className="proven">Cost Optimization.</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Transforming the banking landscape with AI-powered
                      modernization to optimize costs and drive efficiency.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pro-data-finance-two">
                <div className="col-md-1 new-text-finance-two">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-finance">
                  <h5 className="proven">Higher satisfaction quotient</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Transforming the banking landscape through AI-driven
                      modernization to elevate customer satisfaction levels to
                      new heights.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pro-data-finance-three">
                <div className="col-md-1">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-finance">
                  <h5 className="proven">
                    Dated algorithmic and analytics solutions
                  </h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Transforming the banking landscape by revolutionizing with
                      AI-driven modernization to replace outdated algorithmic
                      and analytics solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-finance-color">
        <div>
          <div className="container SERVICE-Retail">
            <h4 className="financE-service">Services</h4>
            <p className="container col-md-7 enterprises-paragraph">
              Enabling financial services and retail banking enterprises with AI
              and analytics throughout the entirety of the customer lifecycle
              journey.
            </p>
          </div>
          <div className="container">
            <div className="row service-life-finance">
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-finance-1.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Data Engineering</h4>
                  <p className="data-sector-paragraph-01">
                    DataObserve empowers the financial services sector through
                    extensive data engineering and modernization services,
                    fostering insights creation and adoption across all
                    decision-making levels. Our solutions emphasize cost
                    reduction, avoidance of technical debt, and long-term
                    sustainability.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-finance-2.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Customer Experience Management &Digital
                  </h4>
                  <p className="data-sector-paragraph-01">
                    As a pioneer in customer analytics, DataObserve offers
                    comprehensive solutions for end-to-end customer experience
                    analytics, strategy, and governance. Our services aim to
                    elevate personalization, foster a well-regulated analytics
                    culture, and deliver real-time customer intelligence to
                    diminish churn, enrich experiences, and amplify revenue
                    streams.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-finance-3.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">ML-Ops</h4>
                  <p className="data-sector-paragraph-01">
                    MLOps offers a robust framework for scaling the AI lifecycle
                    effectively. Recognizing the significance of scalability,
                    DataObserve introduces MLWorks, a comprehensive solution
                    that automates workflows, integrates pre-built accelerators,
                    monitors model degradation, manages code workflows, and
                    streamlines model monitoring across the enterprise.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-finance-4.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Fraud Intelligence</h4>
                  <p className="data-sector-paragraph-01">
                    In the face of persistent financial fraud and online scams
                    affecting organizations and customers worldwide, banks
                    confront the challenge of countering increasing levels of
                    sophistication in intentional fraud, third-party account
                    takeover, and money laundering. These malicious activities
                    constantly evolve, prompting banks to innovate while
                    maintaining a seamless customer experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Solutions-last-div">
          <div className="container SERVICE-Retail">
            <h4 className="financE-service-01">SOLUTIONS</h4>
            <p className="container col-md-8 enterprises-paragraph-01">
              We prioritize guiding you towards the right course of action,
              eliminating uncertainties, and fostering confident progress
              through the integration of AI and analytics capabilities.
            </p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="sidebar-Top-ONE">
                  <ul className="sideBar-Scroll-MENU">
                    <li>
                      <a
                        href="#insights"
                        className = {
                          activeLink === "insights"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO Insights
                      </a>
                    </li>

                    <li>
                      <a
                        href="#explorer"
                        className={
                          activeLink === "explorer"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO EXPLORER
                      </a>
                    </li>
                    <li>
                      <a
                        href="#mlworks"
                        className={
                          activeLink === "mlworks"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO MLWORKS
                      </a>
                    </li>
                    <li>
                      <a
                        href="#feedback"
                        className={
                          activeLink === "feedback"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO Feedback
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tlap"
                        className={
                          activeLink === "tlap" ? "nav-link active" : "nav-link"
                        }
                      >
                        DO TLAP
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <section id="insights">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO Insights</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          With DO Insights, we assist in crafting a customer
                          analytics data model that combines a wide array of
                          customer attributes to enhance your customer
                          engagement endeavors, whether you're focused on
                          cultivating new customer relationships or nurturing
                          existing clients.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO INSIGHTS</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            With DO Insights, we assist in crafting a customer
                            analytics data model that combines a wide array of
                            customer attributes to enhance your customer
                            engagement endeavors, whether you're focused on
                            cultivating new customer relationships or nurturing
                            existing clients.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="explorer">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">Customer EXPLORER</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DO Explorer offers a comprehensive 360° perspective of
                          your customers, providing actionable behavioral
                          insights that empower you to cultivate financial
                          experiences through a unique personalization strategy,
                          tailored specifically for high-net-worth clients.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">
                            Customer EXPLORER
                          </h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Create & Save Segments.</h4>
                          <h4>CGather CX INSIGHTS.</h4>
                          <h4>SEGMENT ANALYSIS & cOMPARISON.</h4>
                          <h4>eXPORT SEGMENTS.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DO Explorer offers a comprehensive 360° perspective
                            of your customers, providing actionable behavioral
                            insights that empower you to cultivate financial
                            experiences through a unique personalization
                            strategy, tailored specifically for high-net-worth
                            clients.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="mlworks">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO MLWORKS</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          Leveraging predictive analytics and modern tools such
                          as geospatial clustering and rule-based AI, fraud
                          prevention aids financial services entities in
                          seamlessly detecting and preventing fraud across
                          various domains including credit cards, checking
                          accounts, insurance, and beyond.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">ML-WORKS</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360</h4>
                          <h4>Customer behaviour intelligence</h4>
                          <h4>Application Insights & Reporting</h4>
                          <h4>Next best experience engine</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            Leveraging predictive analytics and modern tools
                            such as geospatial clustering and rule-based AI,
                            fraud prevention aids financial services entities in
                            seamlessly detecting and preventing fraud across
                            various domains including credit cards, checking
                            accounts, insurance, and beyond.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="feedback">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO Feedback</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          With our self-learning algorithm-driven feedback loops
                          and data governance facilitators, financial services
                          organizations experience enhanced data management,
                          resulting in tangible operational and business
                          benefits.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO Feedback</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Quality management</h4>
                          <h4>Hierarchy Management</h4>
                          <h4>Data Governance</h4>
                          <h4>Feedback LOOP</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            With our self-learning algorithm-driven feedback
                            loops and data governance facilitators, financial
                            services organizations experience enhanced data
                            management, resulting in tangible operational and
                            business benefits.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="tlap">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO TLAP</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          DO TLAP serves as a comprehensive facilitator for
                          planning, designing, and executing financial services
                          marketing campaigns. The Test and Learn Platform
                          (TALP) offers metrics that you can harness to improve
                          your campaign performance.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO TLAP</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>cONFIGURABLE INTERFACE</h4>
                          <h4>350 pERFORMANCE VIEW & ADJACENT LEARNING</h4>
                          <h4>iNTEGRATED WORKFLOE & AI BASED TECHNIQUE</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DO TLAP serves as a comprehensive facilitator for
                            planning, designing, and executing financial
                            services marketing campaigns. The Test and Learn
                            Platform (TALP) offers metrics that you can harness
                            to improve your campaign performance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-finance-02">
            <div className="gradient-layer-finance-01">
              <img
                src="./images/building-finance.jpeg"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-8 ai-ana-home">
                    Enhancing industry standards through the integration of AI
                    and analytics.
                  </p>
                  <p className="container col-md-7 unique-home">
                    Each industry presents its unique challenges, and for
                    numerous pressing issues, there are no established
                    playbooks. Achieving solutions requires a delicate balance
                    between adhering to best practices and engaging in
                    fundamental, ground-up thinking.
                  </p>
                  <a style={{ textDecoration: "none" }}>
                    <button
                      className="Homepage-Contact-Us-3"
                      onClick={handleContactButtonClick}
                    >
                      Contact
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding">
            <div className="col-md-6">
              <h4 className="Advantage-do">The DATAOBSERVE advantage</h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking">
                Explore how DataObserve's pioneering data-driven banking
                analytics solutions enable clients to provide personalized and
                secure customer experiences.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <img
                  src="./images/speed.png"
                  className="value-Speed"
                  alt="speed"
                  width={100}
                />
                <h4 className="speed-finance">
                  Accelerate speed to <br />
                  value
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Through harnessing our arsenal of 30+ customer tools and
                  accelerators tailored for the financial services industry, we
                  deliver value 50% faster than off-the-shelf solutions.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="./images/winning.png"
                  className="value-Speed"
                  alt="speed"
                  width={100}
                />
                <h4 className="speed-finance">
                  Winning partnership
                  <br />
                  ecosystem
                </h4>
                <p className="col-md-12 speed-paragraph">
                  Revamp your analytics operations by tapping into our vast
                  partner ecosystem, comprising a proprietary suite of
                  accelerators and hyperscaler platforms.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <img
                  src="./images/bank.png"
                  className="value-Speed"
                  alt="speed"
                  width={100}
                />
                <h4 className="speed-finance">
                  Bank on a proven
                  <br /> Partner in customer analytics
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Our customers acknowledge us as trusted providers and partners
                  in Customer Analytics Services.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="./images/grow.png"
                  className="value-Speed"
                  alt="speed"
                  width={100}
                />
                <h4 className="speed-finance">
                  Build resilience in the <br /> face of competition
                </h4>
                <p className="col-md-12 speed-paragraph">
                  With our proficiency and readiness to deliver large-scale
                  analytics solutions for both B2B and B2C businesses, coupled
                  with our track record of supporting top-tier retail and CPG
                  enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="video" className="landing-page-what-we-do-02-finance">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">GAMING</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/gamingnew" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">Travel & Hospitality</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/travel" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">CPG</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/cpg" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Financial;
