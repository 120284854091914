import React, { useState } from "react"
import "./Contact.css"
import Navbar from "../products/Navbar"
import Footer from "../products/Footer"

import ReCAPTCHA from "react-google-recaptcha"

function ContactNew() {
  const [verfied, setVerifed] = useState(false)

  function onChange(value) {
    console.log("Captcha value:", value)
    setVerifed(true)
  }

  return (
    <>
      <div>
        <section>
          <Navbar />
        </section>
        <section>
          <div className="contact-Position">
            <div className="background-contact-Color">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 contact-text-paragraph">
                    <h3 className="contact-US-New">Contact Us</h3>
                    <p className="col-md-11 contact-new-paragraph">
                      At DataObserve, our aim is to unite technology innovators
                      and creative problem solvers. Consider joining our
                      continuously expanding team for a fulfilling and enduring
                      career.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="women-section-height">
              <div>
                <img
                  src="./images/women.png"
                  alt="women"
                  className="women-landingpage-Contact"
                />
              </div>
            </div>
            <div className="contact-position-two">
              <div className="background-form-Color">
                <h4 className="Fill-Details">
                  Fill the details to connect with us
                </h4>
                <form>
                  <div class="form-group">
                    <label for="inputAddress" className="contact-Name">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputAddress"
                      // placeholder="1234 Main St"
                    />
                  </div>
                  <label for="inputCity" className="contact-Name">
                    Phone Number
                  </label>

                  <div class="row">
                    <div class="col-md-3 form-group">
                      <input
                        type="number"
                        class="form-control"
                        inputmode="numeric"
                        id="inputCity"
                      />
                    </div>

                    <div class="col-md-9 form-group">
                      {/* <label for="inputZip">Zip</label> */}
                      <input type="number" class="form-control remove-arrow"  inputmode="numeric" id="inputZip" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputAddress" className="contact-Name">
                      Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputAddress"
                      // placeholder="1234 Main St"
                    />
                  </div>
                  <div class="form-group">
                    <label for="inputAddress2" className="contact-Name">
                      Message
                    </label>
                    <textarea
                      type="text"
                      class="form-control HEIGHT-TEXTAREA"
                      id="inputAddress2"
                      // placeholder="Apartment, studio, or floor"
                    />
                  </div>

                  <ReCAPTCHA
                    sitekey="6LdYhZwpAAAAAA-FAC2_6rPXxjf_drrtte6_HQm5"
                    onChange={onChange}
                  />
                  <button
                    type="submit"
                    class="contact-send-message"
                    disabled={!verfied}
                  >
                    Send my message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="Gap-our-location">
            <div className="container">
              <div className="row">
                <h3 className="OUR-LOCATIONS-CONTACT">Our Location</h3>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12 india-bg-color-1">
                  <div className="India-Hyderabad">
                    <h3 className="India-Contact">India</h3>
                    <h4 className="hyderabad-contact">Hyderabad</h4>
                    <p className="col-md-4 kothaguda-contact">
                      Kothaguda, North Hyderabad, Hyderabad, Ranga Reddy
                      District, Telangana, India. 500084
                    </p>
                  </div>
                </div>
                <div className="col-md-12 india-bg-color">
                  <img
                    src="./images/india-locations.png"
                    alt="india"
                    className="India-locations"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="padding-Locations">
            <div className="container">
              <div className="row">
                <h1 className="upcoming-Locations">Upcoming locations</h1>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <img src="./images/canada.png" alt="canada" width={212} />
                <img
                  src="./images/northamerica.png"
                  alt="canada"
                  width={212}
                  className="country-images-gap"
                />
                <img
                  src="./images/dubai.png"
                  alt="canada"
                  width={212}
                  className="country-images-gap"
                />
                <img
                  src="./images/amesterdam.png"
                  alt="canada"
                  width={212}
                  className="country-images-gap"
                />
                <img
                  src="./images/singapore.png"
                  alt="canada"
                  width={212}
                  className="country-images-gap"
                />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default ContactNew
