import React from "react"
import "./Peoplecarrier.css"
import Navbar from "../products/Navbar"
import footerland from "../../src/videos/footerland.mp4"

const Peoplecarrier = () => {
  return (
    <div>
      <section>
        <Navbar />
      </section>
      <div className="overall-peop-carr">
        <section1>
          <div className="peop-carr-vid">
            <div className="overlay-landing-page">
              <div className="gradient-layer">
                {/* <video className="peop-carr-videos" autoPlay loop muted>
                  <source
                    src="https://storage.googleapis.com/do-website-resources/gaming.mp4"
                    type="video/mp4"
                  />
                </video> */}

                <video className="peop-carr-videos" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="container peop-carr-text">
              <div className="row">
                <div className="">
                  <h1 className="col-md-7 peop-carr-h1">PEOPLE & CARRERS</h1>
                  <p className="col-md-6 peop-carr-para1">
                    At DataObserve, our aim is to unite technology innovators
                    and creative problem solvers. Consider joining our
                    continuously expanding team for a fulfilling and enduring
                    career.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section1>
        {/*  */}
        <section2>
          <div className="sec-2-blue">
            <div className="container">
              <div className="row sec-2-div1">
                <div className="col-md-9">
                  <p className="col-md-9 sec-2-join-h1">
                    JOIN A GROUP OF PASSIONATE, TALENTED INDIVIDUALS PIONEERING
                    THE FUTURE OF DATA.
                  </p>
                </div>

                <div className="sec-2-btn">
                  <button className="view-opn-btn">View open positions</button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="sec-2-blue">
            <h1 className="sec-2-join-h1">
              JOIN A GROUP OF PASSIONATE, TALENTED INDIVIDUALS PIONEERING THE
              FUTURE OF DATA.
            </h1>
            <button className="view-opn-btn">View open positions</button>
          </div> */}
        </section2>
        {/*  */}
        <section3>
          <div className="sec-3-why-j-o">
            <h1 className="w-j-do-h1">WHY JOIN DATAOBSERVE?</h1>
          </div>
          <div className="container nine-items">
            <div className="row Multi-text-gap">
              <div className="col-md-3">
                <div>
                  <h1 className="nine-items-h1">MULTICULTURAL ATMOSPHERE</h1>
                  <p className="nine-items-para">
                    Work with the best from all over the globe, and get input
                    from different cultures and mindsets— from sunny Singapore
                    to colorful Turkey and lush Denmark.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div>
                  <h1 className="nine-items-h1">
                    LONG-TERM Vision, Short-Term Execution
                  </h1>
                  <p className="nine-items-para">
                    Your work will have a long-term focus. The luxury of
                    long-term thinking is possible because we also love
                    short-term execution. Simply put, we get stuff done.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div>
                  <h1 className="nine-items-h1">Your Work Does the Talking</h1>
                  <p className="nine-items-para">
                    Unlike many other work environments, superficial stuff—such
                    as your stature, clothes or car— has zero impact on our
                    perception of you. We judge you on your results alone.
                  </p>
                </div>
              </div>

              <div className="col-md-3">
                <div>
                  <h1 className="nine-items-h1">Introverts are Welcome, Too</h1>
                  <p className="nine-items-para">
                    You’ll join a result- oriented, diversity- focused company.
                    You’ll thrive if you’re an introvert—just like your
                    extroverted colleagues also do.
                  </p>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row Multi-text-gap">
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">
                      State-of-the-Art Collaboration Tools
                    </h1>
                    <p className="nine-items-para">
                      You’ll use state-of-the- art online tools to facilitate
                      smooth and enjoyable teamwork with your colleagues.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">
                      Paid, Completely Flexible Holidays
                    </h1>
                    <p className="nine-items-para">
                      You’ll have paid holidays and can take them whenever you’d
                      like. No complicated internal procedures or approval
                      processes.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">Great Gender Inclusivity</h1>
                    <p className="nine-items-para">
                      Brilliant ideas don’t have a gender. We take gender
                      inclusivity seriously and are proud of our diverse and
                      accepting team.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">Ambitious Colleagues</h1>
                    <p className="nine-items-para">
                      You’ll have colleagues with incredible ambitions and
                      intelligence—but without the elbows. That’s because we
                      only work with team mates who combine professional
                      ambitions with the ability to work in a team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row Multi-text-gap">
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">A Refined Design Process</h1>
                    <p className="nine-items-para">
                      We continually re-design and re-invent our organization
                      and platform through a solid in-house design process
                      —mixing agile and user- centered design.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">
                      Uninterrupted Concentration Time
                    </h1>
                    <p className="nine-items-para">
                      You avoid working in an open office space with constant
                      interruptions. Instead, you can immerse yourself in your
                      work, deliver great results fast, and expand your skills.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">
                      We Trust You to Deliver Results
                    </h1>
                    <p className="nine-items-para">
                      You’ll work remotely and have autonomy and freedom. No
                      manager or colleague will look over your shoulder as long
                      as you live up to that responsibility.
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div>
                    <h1 className="nine-items-h1">
                      Social, but Not Intrusive, Atmosphere
                    </h1>
                    <p className="nine-items-para">
                      Working remotely strikes the perfect balance between being
                      inspired and social but not getting interrupted.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="last-ittemm col-md-3">
              <div>
                <h1 className="nine-items-h1">Zero Bureaucracy</h1>
                <p className="nine-items-para">
                  You’ll have absolutely zero bureaucracy and the power to make
                  your own decisions and hold expenses.
                </p>
              </div>
            </div>
          </div>
        </section3>
        {/* <section4>
          <div className=" sec-4-open-pos">
            <div className="  sec-4-overrall">
              <div className="container sec-4-div-1">
                <h1 className="container col-md-7 op-position">
                  Open Positions
                </h1>
                <p className="container col-md-7  op-para">
                  Even when we’re not actively hiring, we’re always eager to
                  meet passionate, driven individuals.
                </p>
              </div>
              <div className=" container sec-4-div-2">
                <h1 className="container col-md-7 h1-dev">Development</h1>
                <p className="container col-md-6 dev-para">
                  Senior PHP (Laravel) Developer
                </p>{" "}
               
                <p className=" container col-md-6 dev-para">
                  Lead Front-End/UI Developer
                </p>
               
              </div>
              <div className="container sec-4-div3">
                <h1 className="container col-md-7 h1-dev">Design</h1>
                <p className="container col-md-6 dev-para">
                  Graphic & Motion Designer
                </p>
                
                <p className="container col-md-6 dev-para">UI/UX Designer</p>
                
              </div>
              <div className="container">
                <ul>
                  <li className=" h1-dev">Support and Operations</li>
                  <li className="  dev-para">Admin and Support Specialist</li>
                </ul>
                
              </div>
            </div>
          </div>
        </section4> */}

        {/* <section4>
          <div className="last-main">
            <div>
              <h1 className="op-position">Open Positions</h1>
              <p className="op-para">
                Even when we’re not actively hiring, we’re always eager to meet
                passionate, driven individuals.
              </p>
            </div>

            <div className="  sec-4-div-2">
              <h1 className=" h1-dev">Development</h1>
              <p className=" dev-para">Senior PHP (Laravel) Developer</p>{" "}
              
              <p className="dev-para">Lead Front-End/UI Developer</p>
             
            </div>
            <div className="sec-4-div3">
              <h1 className="h1-dev">Design</h1>
              <p className="dev-para">Graphic & Motion Designer</p>
             
              <p className=" dev-para">UI/UX Designer</p>
             
            </div>
            <div className="sec-4-div4">
              <h1 className=" h1-dev">Support and Operations</h1>
              <p className="  dev-para">Admin and Support Specialist</p>

              
            </div>
          </div>
        </section4> */}

        <div className="sec-4-starting">
          <div className="row row-items">
            <div className="col-md-4"></div>
            <div className="sec-4-overrall col-md-5">
              <div className="sec-4-div-1">
                <h1 className="op-position">Open Positions</h1>
                <p className="op-para">
                  Even when we’re not actively hiring, we’re always eager to
                  meet passionate, driven individuals.
                </p>
              </div>
              <div className="sec-4-div-2">
                <h1 className=" h1-dev">Development</h1>
                <p className=" dev-para">Senior PHP (Laravel) Developer</p>
                <hr />
                <p className="dev-para">Lead Front-End/UI Developer</p>
                <hr />
              </div>
              <div className="sec-4-div3">
                <h1 className="h1-dev">Design</h1>
                <p className="dev-para">Graphic & Motion Designer</p>
                <hr />

                <p className=" dev-para">UI/UX Designer</p>
                <hr />
              </div>
              <div className="sec-4-div4">
                <h1 className=" h1-dev">Support and Operations</h1>
                <p className="  dev-para">Admin and Support Specialist</p>
                <hr />
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <section5>
          <div className="last-main-black">
            <div className="container ">
              <div className="row last-div-1">
                <div className="col-md-3"></div>
                <div className="col-md-3">
                  <p className="last-para-tag">join our mission today.</p>
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3 button-last-main">
                  <button className="last-btn-tag">view open positions</button>
                </div>
              </div>
            </div>
          </div>
        </section5>
      </div>
    </div>
  )
}

export default Peoplecarrier
