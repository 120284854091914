import React from "react"
import "./Insighttrending.css"
import Navbar from "../products/Navbar"
import Footer from "../products/Footer"

function Insighttrending() {
  return (
    <div>
      <Navbar />

      <section className="insight-back-color">
        <div className="container down-spce">
          <div className="trending">
            <h1>Trending on Dataobserve</h1>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex pl-0px">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-01.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <a href="#" style={{ textDecoration: "none" }}>
                  <p className="insigh-text">
                    Core Principles Of Design Thinking
                  </p>
                </a>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-02.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <p className="insigh-text">
                  Enhanced data management for a fintech firm using Snowflake
                </p>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-03.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <p className="insigh-text">
                  AWS FOR APPLICATION MODERNIZATION: UPDATING YOUR LEGACY
                  SYSTEMS FOR THE CLOUD
                </p>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex pl-0px">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-04.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <p className="insigh-text">
                  The future of data engineering in digital product engineering
                  lies with Gen AI
                </p>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-05.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <p className="insigh-text">
                  How Does Multi-cloud Differ From A Hybrid Cloud
                </p>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="col-md-1 ending-width">
                <img
                  src="./images/Trending-06.png"
                  className="Trending-logo"
                  alt="logo"
                />
              </div>
              <div className="col-md-11 ending-width">
                <p className="insigh-text">
                  Design Thinking Led Approach to Building Digital Product
                  Ecosystem
                </p>
                <div className="d-flex">
                  <p className="insigh-text-date">Mar 15, 2024</p>
                  <p className="insigh-dash">.</p>
                  <p className="insigh-text-read">7 min read</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="trending-sit">
        <div className="container">
          <div className="space-gap">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                {/* <div className="d-flex"> */}
                <a
                  class="nav-item  nav-link active Blogs-Insights"
                  id="nav-home-tab"
                  data-toggle="tab"
                  href="#nav-home"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Blogs
                </a>
                <a
                  class="nav-item nav-link Blogs-Insights"
                  id="nav-profile-tab"
                  data-toggle="tab"
                  href="#nav-profile"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                >
                  Articles
                </a>
                <a
                  class="nav-item nav-link Blogs-Insights"
                  id="nav-contact-tab"
                  data-toggle="tab"
                  href="#nav-contact"
                  role="tab"
                  aria-controls="nav-contact"
                  aria-selected="false"
                >
                  Case Studies
                </a>
                <a
                  class="nav-item nav-link Blogs-Insights"
                  id="nav-Books-tab-1"
                  data-toggle="tab"
                  href="#nav-Books"
                  role="tab"
                  aria-controls="nav-Books"
                  aria-selected="false"
                >
                  E-Books & Whitepapers
                </a>

                {/* </div> */}
              </div>
            </nav>
            <div className="consider-save">
              <div>
                <img
                  src="./images/save-01.png"
                  className="Save-logo"
                  alt="logo"
                />
              </div>
              <div>
                <a href="#">
                  {" "}
                  <p className="save-text-date">Saved</p>
                </a>
              </div>
            </div>
          </div>
          <hr style={{ marginTop: "-7px" }} />

          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="container">
                <div className="row">
                  <div className="Marking-insight">
                    <div className="trending-list-1">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/corePrinciples/corePrinciples1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>Core Principles Of Design Thinking</b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>

                        <button type="button" class="btn-dark-domain">
                          Design
                        </button>

                        <a href="blog/1">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/greenfield/greenfield1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Key considerations for Greenfield Product
                            Engineering to build futuristic solutions
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Product Engineering
                        </button>
                        <a href="blog/2">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 12, 2024</p>

                            <p className="insigh-text-read-min">5 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/dataSecOps/data1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            DataSecOps — Key To Data As A Product Enablement
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          DataSecOps
                        </button>
                        <a href="blog/3">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/modernStack/stack1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>Challenges Within The Modern Data Stack</b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Engineering
                        </button>
                        <a href="blog/4">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/dataGovernance/governance1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Enhance Your Data Governance With These 7
                            Unconventional Data Observability Use Cases
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Engineering
                        </button>
                        <a href="blog/5">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/multiCloud/multiCloud1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>How Does Multi-Cloud Differ From A Hybrid Cloud</b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Engineering
                        </button>
                        <a href="blog/6">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/designThinking/design1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Design Thinking Led Approach To Building Digital
                            Product Ecosystem
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Design
                        </button>
                        <a href="blog/7">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/bigDataConnect/ethical1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Ethical Bias In AI-Based Security Systems: The Big
                            Data Disconnect
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          AI Engineering{" "}
                        </button>
                        <a href="blog/8">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="images/blogImages/dataAsProduct/dataAsProduct.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Data as a Product: Data Architecture Principles for
                            Management Blueprint
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Engineering
                        </button>
                        <a href="blog/9">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="images/blogImages/dataStrategy/strategy1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Data as a Product: The Role of Data Architecture and
                            Data Modelling Strategy
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Architecture
                        </button>
                        <a href="blog/10">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="/images/blogImages/applicationArchitecture/deepDive20.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Deep Dive into Application Architecture:
                            Understanding the Concepts Through a Real-World
                            Example
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Data Architecture
                        </button>
                        <a href="blog/11">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 matter-one">
                    <p className="Discover-Marking">
                      Discover more of what matters to you
                    </p>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons">
                        Programming
                      </button>
                      <button type="button" class="more-buttons-1">
                        Data Science
                      </button>
                      <button type="button" class="more-buttons-2">
                        Technology
                      </button>
                    </div>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-3">
                        Self Improvement
                      </button>
                      <button type="button" class="more-buttons-4">
                        Writing
                      </button>
                      <button type="button" class="more-buttons-5">
                        Relationships
                      </button>
                    </div>
                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-6">
                        Machine Learning
                      </button>
                      <button type="button" class="more-buttons-7">
                        Productivity
                      </button>
                      <button type="button" class="more-buttons-8">
                        Politics
                      </button>
                    </div>

                    <a href="#">
                      <p className="Most-Marking">See more topics</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="container">
                <div className="row">
                  <div className="Marking-insight">
                    <div className="trending-list-1">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/article-show.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            INTRO TO LLM AGENTS WITH LANGCHAIN: WHEN RAG IS NOT
                            ENOUGH
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>

                        <button type="button" class="btn-dark-domain">
                          Article
                        </button>

                        <a href="article">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>

                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 matter-one">
                    <p className="Discover-Marking">
                      Discover more of what matters to you
                    </p>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons">
                        Programming
                      </button>
                      <button type="button" class="more-buttons-1">
                        Data Science
                      </button>
                      <button type="button" class="more-buttons-2">
                        Technology
                      </button>
                    </div>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-3">
                        Self Improvement
                      </button>
                      <button type="button" class="more-buttons-4">
                        Writing
                      </button>
                      <button type="button" class="more-buttons-5">
                        Relationships
                      </button>
                    </div>
                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-6">
                        Machine Learning
                      </button>
                      <button type="button" class="more-buttons-7">
                        Productivity
                      </button>
                      <button type="button" class="more-buttons-8">
                        Politics
                      </button>
                    </div>

                    <a href="#">
                      <p className="Most-Marking">See more topics</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <div className="container">
                <div className="row">
                  <div className="Marking-insight">
                    <div className="trending-list-1">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/snowflake-aws.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Enhanced data management for a fintech firm using
                            Snowflake
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>

                        <button type="button" class="btn-dark-domain">
                          Fintech
                        </button>

                        <a href="casestudies">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/manfacture-casestudy.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Maximizing manufacturing efficiency with data
                            engineering
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Manufacturing
                        </button>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 12, 2024</p>

                            <p className="insigh-text-read-min">5 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/empower-casestudy.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Empoering a fashion e-commerce brand with advanced
                            HR analytics
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          E-Commerce
                        </button>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 matter-one">
                    <p className="Discover-Marking">
                      Discover more of what matters to you
                    </p>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons">
                        Programming
                      </button>
                      <button type="button" class="more-buttons-1">
                        Data Science
                      </button>
                      <button type="button" class="more-buttons-2">
                        Technology
                      </button>
                    </div>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-3">
                        Self Improvement
                      </button>
                      <button type="button" class="more-buttons-4">
                        Writing
                      </button>
                      <button type="button" class="more-buttons-5">
                        Relationships
                      </button>
                    </div>
                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-6">
                        Machine Learning
                      </button>
                      <button type="button" class="more-buttons-7">
                        Productivity
                      </button>
                      <button type="button" class="more-buttons-8">
                        Politics
                      </button>
                    </div>

                    <a href="#">
                      <p className="Most-Marking">See more topics</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="nav-Books"
              role="tabpanel"
              aria-labelledby="nav-Books-tab-1"
            >
              <div className="container">
                <div className="row">
                  <div className="Marking-insight">
                    <div className="trending-list-1">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/ebook-aws-1.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            AWS FOR APPLICATION MODERNIZATION: UPDATING YOUR
                            LEGACY SYSTEMS FOR THE CLOUD
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>

                        <button type="button" class="btn-dark-domain">
                          Cloud Migration
                        </button>

                        <a href="ebooks">
                          <h6 className="Read-More-trending">Read more</h6>
                        </a>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/ebook-important-2.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Why is it important to understand application
                            modernization more than ever?
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          Application modernization
                        </button>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 12, 2024</p>

                            <p className="insigh-text-read-min">5 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="trending-list">
                      <div className="col-md-2 ending-width">
                        <img
                          src="./images/ebook-enhance-3.png"
                          className="blogs-Trending-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-6 ending-width">
                        <p className="Marking-insigh-text">
                          <b>
                            Enhance your software development process with
                            DevOps
                          </b>
                        </p>
                        <p className="insigh-text-Marking">
                          Sir Tim Berners-Lee’s open letter to mark the occasion
                          of the Web’s 35th Birthday.
                        </p>
                        <button type="button" class="btn-dark-domain">
                          DevOps{" "}
                        </button>
                        <div className="Marking-ending">
                          <div className="d-flex">
                            <p className="insigh-text-date-mar">Mar 15, 2024</p>

                            <p className="insigh-text-read-min">7 min read</p>
                          </div>
                          <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 matter-one">
                    <p className="Discover-Marking">
                      Discover more of what matters to you
                    </p>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons">
                        Programming
                      </button>
                      <button type="button" class="more-buttons-1">
                        Data Science
                      </button>
                      <button type="button" class="more-buttons-2">
                        Technology
                      </button>
                    </div>

                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-3">
                        Self Improvement
                      </button>
                      <button type="button" class="more-buttons-4">
                        Writing
                      </button>
                      <button type="button" class="more-buttons-5">
                        Relationships
                      </button>
                    </div>
                    <div className="more-buttons-02">
                      <button type="button" class="more-buttons-6">
                        Machine Learning
                      </button>
                      <button type="button" class="more-buttons-7">
                        Productivity
                      </button>
                      <button type="button" class="more-buttons-8">
                        Politics
                      </button>
                    </div>

                    <a href="#">
                      <p className="Most-Marking">See more topics</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Insighttrending
