import React, { useEffect, useState } from "react"
import "./Article.css"

function Article() {
  const [activeLink, setActiveLink] = useState("")

  useEffect(() => {
    const handleScroll = () => {
      console.log("sections", window.scrollY)

      const position = window.scrollY
      console.log("position", position)
      if (position < 1200) {
        setActiveLink("insights")
      } else if (position >= 1200 && position < 1200 + 2000) {
        setActiveLink("explorer")
      } else if (position >= 1200 + 2000 && position < 1200 + 2000 * 2) {
        setActiveLink("mlworks")
      } else if (position >= 1200 + 2000 * 2 && position < 1200 + 2000 * 3) {
        setActiveLink("feedback")
      } else {
        setActiveLink("tlap")
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div>
      <section className="article-top-back">
        <div className="container">
          <div className="row">
            <a href="/allinsights" style={{ textDecoration: "none" }}>
              <button className="art-back-btn">Back</button>
            </a>
          </div>
        </div>
      </section>
      <section className="article-sec-1">
        <div className="article-sec-1-div">
          <img className="article-do-logo" src="/images/landing-logo.png" />
          <h1 className="article-sec1-h1">
            Intro to LLM Agents with Langchain: When RAG is Not Enough
          </h1>
          <button className="article-sec1-btn">Article</button>
          <p className="article-sec1-p">Mar 15, 2024 · 7 min read</p>
        </div>
      </section>
      <section className="article-sec-2">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h2 className="lastupdate">Last updated in Jan 2024</h2>
              <p className="dots-3">. . .</p>

              <div id="insights" className="article-sec2-div1">
                <h1 className="article-sec-2-h1s">
                  Let’s fix this example using logical rules
                </h1>
                <p className="article-sec2-div1-p">
                  The following 2 designs are for the property details page of a
                  short-term property rental app. The first one is the original
                  design. The second is the result of applying some logical
                  rules or guidelines.
                </p>
                <img className="article-img-1" src="./images/article1.png" />
                <p className="article-sec2-div1-p">
                  Even without much visual or interaction design experience,
                  you’ll probably notice that the original design feels messy,
                  complicated, and difficult to use. This is because it contains
                  many problematic design details that pose a risk to usability.
                  Perhaps you can already spot a few?
                </p>
                <p className="article-sec2-div1-p">
                  Let’s fix the problems with the original design one at a time
                  using the following logical rules or guidelines:
                </p>
                <ul className="li-16">
                  <li className="li-s">Use space to group related elements</li>
                  <li className="li-s">Be consistent</li>
                  <li className="li-s">
                    Ensure similar looking elements function similarly
                  </li>
                  <li className="li-s">Create a clear visual hierarchy</li>
                  <li className="li-s">Remove unnecessary styles</li>
                  <li className="li-s">Use colour purposefully</li>
                  <li className="li-s li-s-d">
                    {" "}
                    <span className="interface-Elements">
                      Ensure interface elements have a 3:1 contrast ratio{" "}
                    </span>
                  </li>
                  <li className="li-s li-mar">
                    Ensure text has a 4.5:1 contrast ratio
                  </li>
                  <li className="li-s">
                    Don’t rely on colour alone as an indicator
                  </li>
                  <li className="li-s">Use a single sans serif typeface</li>
                  <li className="li-s">
                    Use a typeface with taller lower case letters
                  </li>
                  <li className="li-s">Limit the use of uppercase</li>
                  <li className="li-s">
                    Use regular and bold font weights only
                  </li>
                  <li className="li-s">Avoid pure black text</li>
                  <li className="li-s">Left align text</li>
                  <li className="li-s">
                    Use at least 1.5 line height for body text
                  </li>
                </ul>
              </div>

              <div id="explorer" className="article-sec2-div2">
                <h1 className="article-sec-2-h1s">
                  1. Use space to group related elements
                </h1>
                <p className="article-sec2-div1-p">
                  Breaking up information into smaller groups of related
                  elements helps to structure and organise an interface. This
                  makes it faster and easier for people to understand and
                  remember.
                </p>
                <p className="article-sec2-div1-p">
                  You can group related elements using the following methods:
                </p>
                <ul>
                  <li className="article-sec2-div1-p">
                    Place related elements in the same container
                  </li>
                  <li className="article-sec2-div1-p">
                    Space related elements close together
                  </li>
                  <li className="article-sec2-div1-p">
                    Make related elements look similar
                  </li>
                  <li className="article-sec2-div1-p">
                    Align related elements in a continuous line
                  </li>
                </ul>
                <p className="article-sec2-div1-p">
                  Using containers is the strongest visual cue to group
                  interface elements, but it can add unnecessary clutter. Look
                  for opportunities to use other grouping methods, they’re often
                  more subtle and can help simplify designs.
                </p>
                <p className="article-sec2-div1-p">
                  Using space in particular is a very effective and simple way
                  to group related elements. You can also combine grouping
                  methods to help display groupings more clearly.
                </p>
                <p className="article-sec2-div1-p">
                  In our example, the lack of space between content makes the
                  design look cluttered and difficult to understand. Increasing
                  spacing helps to clearly group content, making it more
                  organised and easier to understand.
                </p>
                <img className="article-img-1" src="./images/article1.png" />
              </div>
              <p className="dots-3">. . .</p>
              <div id="mlworks" className="article-sec2-div3">
                <h1 className="article-sec-2-h1s">2. Be consistent</h1>
                <p className="article-sec2-div1-p">
                  Consistency in UI design means that similar elements look and
                  work in a similar way. This should be true both within your
                  product and when compared with other well-established
                  products. This predictable functionality improves usability
                  and reduces errors, as people don’t need to keep learning how
                  things work.
                </p>
                <p className="article-sec2-div1-p">
                  In our example, the icon styles are inconsistent, as some are
                  filled and others aren’t. This could confuse some people, as
                  filled icons often indicate that an element is selected.
                  Outlining all icons with a 2pt stroke weight and rounded
                  corners improves consistency and gives each icon a similar
                  visual weight.
                </p>
                <img className="article-img-3" src="./images/article3.png" />
                <p className="article-sec2-div1-p">
                  Text labels are also added to the icons to help ensure people
                  can understand what they mean, especially those using screen
                  readers (software that describes an interface, using speech or
                  braille, to someone who can’t see it).
                </p>
                <img className="article-img-1" src="./images/article1.png" />
              </div>
              <p className="dots-3">. . .</p>
              <div id="feedback" className="article-sec2-div3">
                <h1 className="article-sec-2-h1s">
                  3. Ensure similar looking elements function similarly
                </h1>
                <p className="article-sec2-div1-p">
                  If elements look similar, people will expect them to work in a
                  similar way. So try to ensure that you use a consistent visual
                  treatment for elements with the same functionality.
                  Conversely, try to ensure elements with different
                  functionality look different.
                </p>
                <p className="article-sec2-div1-p">
                  In our example, the icon containers have a similar visual
                  style to the “book now” button. This makes them seem
                  interactive, even though they’re not. Removing the blue colour
                  and button styling from the icons helps to avoid them being
                  mistaken for interactive elements.
                </p>
                <img className="article-img-1" src="./images/article1.png" />
              </div>
              <p className="dots-3">. . .</p>
            </div>
            <div className="col-md-1"></div>

            <div className="col-md-4">
              <div className="article-sidebar-div">
                <p className="Discover-Marking">Tags</p>
                <div className="more-buttons-02">
                  <button type="button" class="more-buttons">
                    Programming
                  </button>
                  <button type="button" class="more-buttons-1">
                    Data Science
                  </button>
                  <button type="button" class="more-buttons-2">
                    Technology
                  </button>
                </div>

                <div className="more-buttons-02">
                  <button type="button" class="more-buttons-3">
                    Self Improvement
                  </button>
                  <button type="button" class="more-buttons-4">
                    Writing
                  </button>
                  <button type="button" class="more-buttons-5">
                    Relationships
                  </button>
                </div>
                <div className="more-buttons-02">
                  <button type="button" class="more-buttons-6">
                    Machine Learning
                  </button>
                  <button type="button" class="more-buttons-7">
                    Productivity
                  </button>
                  <button type="button" class="more-buttons-8">
                    Politics
                  </button>
                </div>

                <a href="#">
                  <p className="Most-Marking">See more topics</p>
                </a>

                <div className="">
                  <ul className="article-sidebar">
                    <li>
                      <a
                        href="#insights"
                        className={
                          activeLink === "insights" ? "nav-link active" : ""
                        }
                      >
                        Let’s fix this example using logical rules
                      </a>
                    </li>

                    <li>
                      <a
                        href="#explorer"
                        className={
                          activeLink === "explorer"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        1. Use space to group related elements
                      </a>
                    </li>
                    <li>
                      <a
                        href="#mlworks"
                        className={
                          activeLink === "mlworks"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        2. Be consistent
                      </a>
                    </li>
                    <li>
                      <a
                        href="#feedback"
                        className={
                          activeLink === "feedback"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        Topic
                      </a>
                    </li>
                    <li>
                      <a
                        href="#tlap"
                        className={
                          activeLink === "tlap" ? "nav-link active" : "nav-link"
                        }
                      >
                        Topic
                      </a>
                    </li>
                    <li className="article-topic2">
                      <span className="article-sidebar-span">Topic</span>
                    </li>
                    <li className="article-topic3">
                      <span className="article-sidebar-span">Topic</span>
                    </li>
                    <li className="article-topic4">
                      <span className="article-sidebar-span">Topic</span>
                    </li>
                    <li className="article-topic5">
                      <span className="article-sidebar-span">Topic</span>
                    </li>
                  </ul>

                  <div className="art-sidebar-images">
                    <div>
                      <img
                        className="article-hand"
                        src="./images/article-hand.png"
                      />
                      <img
                        className="article-only26k"
                        src="./images/article-only26k.png"
                      />
                      <img
                        className="article-comment"
                        src="./images/article-comment.png"
                      />
                      <img
                        className="article-only244"
                        src="./images/article-only244.png"
                      />
                    </div>
                    <div>
                      <img
                        className="article-save"
                        src="./images/articlesave.png"
                      />
                      <img
                        className="article-mediaplay"
                        src="./images/article-mediaplay.png"
                      />
                      <img
                        className="article-upload"
                        src="./images/article-upload.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="article-sec-3">
        <h1 className="article-more-form">More From</h1>
        <img className="article-do-logo" src="/images/landing-logo.png" />

        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>

              <div className="trending-list-1">
                <div className="col-md-2 ending-width">
                  <img
                    src="./images/article-show.png"
                    className="blogs-Trending-logo"
                    alt="logo"
                  />
                </div>
                <div className="col-md-6 ending-width">
                  <p className="article-Marking-insigh-text">
                    <b>
                      Intro to LLM Agents with Langchain: When RAG is Not Enough
                    </b>
                  </p>

                  <button type="button" class="btn-dark-domain">
                    Domain
                  </button>
                  <div className="Marking-ending">
                    <div className="d-flex">
                      <p className="insigh-text-date-mar">Mar 15, 2024</p>

                      <p className="insigh-text-read-min">7 min read</p>
                    </div>
                    {/* <div className="text-read ms-auto">
                            <img
                              src="./images/save-01.png"
                              className="Save-logo"
                              alt="logo"
                            />
                          </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Article
