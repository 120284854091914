import React, { useState, useEffect } from "react";
import "./Travel.css";
import "./Financial.css";
import finance from "../../src/videos/Finance.mp4";
import footerland from "../../src/videos/footerland.mp4";
import Footer from "../products/Footer";

function Travel() {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      // const sections = document.querySelectorAll("section");
      console.log("sections", window.scrollY);
      // sections.forEach((section) => {
      //   const sectionTop = section.offsetTop;
      //   const sectionHeight = section.clientHeight;

      //   if (window.scrollY >= sectionTop - sectionHeight / 2) {

      //     setActiveLink(section.getAttribute("id"));
      //   }
      // });
      const position = window.scrollY;
      console.log("position", position);
      if (position < 5800) {
        setActiveLink("travelone");
      } else if (position >= 5800 && position < 5800 + 530) {
        setActiveLink("traveltwo");
      } else if (position >= 5800 + 530 && position < 5800 + 530 * 2) {
        setActiveLink("travelthree");
      } else if (position >= 5800 + 530 * 2 && position < 5800 + 530 * 3) {
        setActiveLink("travelfour");
        // } else {
        //   setActiveLink("tlap");
        // }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">
                    Travel & Hospitality
                  </h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="indrdt-section4">
        <div className="financial-height">
          <div className="gradient-layer-finance">
            <video className="indrdt1-height" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/tRAVEL.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="indrdt-1-data container">
          <h1 className="finance-4-h1">Travel & Hospitality</h1>
          <p className="indrdt-4-p  col-md-8">
            Explore a purposeful career journey within a collaborative setting.
          </p>
        </div>
      </section>

      <section>
        <div className="finance-bg">
          <div className="container width-increase">
            <div className="row financial-padding">
              <div className="col-md-12 d-flex ">
                <div className="col-md-3">
                  <h3 className="percentage-number">25%</h3>
                  <p className="finance-paragraph">
                    increase in operational efficiency by automating processes,
                    optimizing resource allocation, and streamlining workflows
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">30%</h3>
                  <p className="finance-paragraph">
                    reduction in fraudulent activities by implementing robust
                    fraud detection algorithms and real-time monitoring systems
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">35%</h3>
                  <p className="finance-paragraph">
                    improvement in revenue through targeted marketing, dynamic
                    pricing strategies, and cross-selling opportunities
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">45%</h3>
                  <p className="finance-paragraph">
                    improvement in demand forecasting accuracy, enabling better
                    capacity planning and resource management
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">Travel & Hospitality</h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft-embark">
                    Embark on a journey from insights to value realization with
                    AI/ML solutions tailored for the travel and hospitality
                    industry.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="third-section"
        style={{ background: "rgba(236, 236, 236, 0.93)" }}
      >
        <div className="observe-why-landing">
          <div>
            <p className="banking-Ai">
              tHE dATAOBSERVE ADVANTAGE IN tRAVEL & <br></br>hospitality
            </p>
          </div>
          <div className="container">
            <div className="row revolutioN-banking">
              <div className="pro-data-finance-one">
                <div className="col-md-1 new-text-travel">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Boost your time to value</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Leverage the DataObserve advantage in Travel & Hospitality
                      to accelerate your time to value.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pro-data-travel-two">
                <div className="col-md-1 new-text-travel-two">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Enhance customer experiences</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Unlock the DataObserve advantage in Travel & Hospitality
                      to elevate customer experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pro-data-travel-three">
                <div className="col-md-1">
                  <img
                    src="./images/completed.svg"
                    className="tick-HOME"
                    alt="star"
                  />
                </div>
                <div className="col-md-11 text-Foremost-travel">
                  <h5 className="proven">Maximize your efficiency</h5>
                  <div className="d-flex disc-banking">
                    <h3 className="disc-dot">.</h3>
                    <p className="banking-finance">
                      Leverage the DataObserve advantage in travel and
                      hospitality to optimize your efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="background-finance-color">
        <div>
          <div className="container SERVICE-Retail">
            <h4 className="financE-service">Services</h4>
            <p className="container col-md-7 enterprises-paragraph">
              Empowering financial services and retail banking enterprises with
              AI and analytics throughout the entirety of the customer lifecycle
              journey.
            </p>
          </div>
          <div className="container">
            <div className="row service-life-finance">
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/machine-learning.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Machine learning and AI-enabled advanced analytics in
                    hospitality
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Utilize machine learning and AI-driven analytics for
                    hospitality and travel management to generate insights
                    throughout the customer journey. Employ our solutions to
                    enhance hyper-personalization, customer acquisition, pricing
                    strategies, promotions, and churn reduction, ultimately
                    fostering increased customer satisfaction.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-migration.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">Data Migration</h4>
                  <p className="data-sector-paragraph-01">
                    Our data migration accelerators facilitate enterprises in
                    harnessing the potential of their data and transitioning to
                    a modern, cloud-native architecture. DataObserve boasts a
                    successful history of supporting large-scale data migration
                    endeavors for global travel and hospitality businesses,
                    expediting modernization ROI through partnerships with
                    prominent hyperscalers, data, and AI companies.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/rapid.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Rapid Insights Experimentation
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Tailor specific datasets for out-of-the-box solutions to
                    swiftly gain insights and a deeper understanding of our
                    clients' customers beyond what traditional platform
                    reporting offers.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/platform.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Platform modernization and implementation services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Our platform modernization and implementation services
                    assist in migrating data from legacy platforms to modern
                    technology stacks, reducing costs and streamlining processes
                    significantly. This enables the development of self-service
                    capabilities and facilitates data-driven decision-making on
                    a consistent basis.
                  </p>
                </div>
              </div>
              <div className="col-md-12 d-flex finance-background-color">
                <div className="col-md-3 FInance-engineering-image">
                  <img
                    className="data-finance-image"
                    src="./images/data-management2.png"
                    alt="data-finance"
                    width={100}
                  />
                </div>
                <div className="col-md-9">
                  <h4 className="Data-engineer-text-01">
                    Data management and managed services
                  </h4>
                  <p className="data-sector-paragraph-01">
                    Utilize our accelerators for data management and managed
                    services, which cleanse your data and continually refine
                    your environment to minimize costs. Harness our prescriptive
                    analytics solutions for hospitality and travel management to
                    obtain real-time insights that enhance customer experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Solutions-last-div">
          <div className="container SERVICE-Retail">
            <h4 className="financE-service-01">SOLUTIONS</h4>
            <p className="container col-md-8 enterprises-paragraph-01">
              Our focus is on guiding you to navigate the right path,
              eliminating uncertainties, and advancing confidently by leveraging
              the capabilities of AI and analytics.
            </p>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <div className="sidebar-Top-ONE">
                  <ul className="sideBar-Scroll-MENU sideBar-Scroll-MENU-travel">
                    <li>
                      <a
                        href="#travelone"
                        className={
                          activeLink === "travelone"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO Insights
                      </a>
                    </li>

                    <li>
                      <a
                        href="#traveltwo"
                        className={
                          activeLink === "traveltwo"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO MLWORKS
                      </a>
                    </li>
                    <li>
                      <a
                        href="#travelthree"
                        className={
                          activeLink === "travelthree"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO TLAP
                      </a>
                    </li>
                    <li>
                      <a
                        href="#travelfour"
                        className={
                          activeLink === "travelfour"
                            ? "nav-link active"
                            : "nav-link"
                        }
                      >
                        DO CX
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="row">
                  <section id="travelone">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO Insights</h4>
                        <p className="col-md-10 product-elevate-solution-financial">
                          With DO Insights, we assist in crafting a customer
                          analytics data model that combines a wide array of
                          customer attributes to enhance your customer
                          engagement endeavors, whether you're focused on
                          cultivating new customer relationships or nurturing
                          existing clients.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO Insights</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            With DO Insights, we assist in crafting a customer
                            analytics data model that combines a wide array of
                            customer attributes to enhance your customer
                            engagement endeavors, whether you're focused on
                            cultivating new customer relationships or nurturing
                            existing clients.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="traveltwo">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO MLWORKS</h4>
                        <p className="product-elevate-solution-financial">
                          Leveraging predictive analytics and modern tools such
                          as geospatial clustering and rule-based AI, fraud
                          prevention aids financial services entities in
                          seamlessly detecting and preventing fraud across
                          various domains including credit cards, checking
                          accounts, insurance, and beyond.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO MLWORKS</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            Leveraging predictive analytics and modern tools
                            such as geospatial clustering and rule-based AI,
                            fraud prevention aids financial services entities in
                            seamlessly detecting and preventing fraud across
                            various domains including credit cards, checking
                            accounts, insurance, and beyond.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="travelthree">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO TLAP</h4>
                        <p className="product-elevate-solution-financial">
                          DO TLAP serves as a comprehensive facilitator for
                          planning, designing, and executing financial services
                          marketing campaigns. The Test and Learn Platform
                          (TALP) offers metrics that you can harness to improve
                          your campaign performance.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO TLAP</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data qUALITY MANAGEMENT</h4>
                          <h4>Hierarchy Management</h4>
                          <h4>Data Governance</h4>
                          <h4>Feedback LOOP</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DO TLAP serves as a comprehensive facilitator for
                            planning, designing, and executing financial
                            services marketing campaigns. The Test and Learn
                            Platform (TALP) offers metrics that you can harness
                            to improve your campaign performance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section id="travelfour">
                    <div className="col-md-12 landing-Product-over-pad-finance">
                      <div className="products-cards-land">
                        <h4 className="Product-land-360">DO CX</h4>
                        <p className="product-elevate-solution-financial">
                          DO CX offers a comprehensive solution, providing a
                          complete view of your customers and deep insights into
                          their needs, concerns, and areas for action.
                        </p>
                      </div>
                      <div className="overlay-product">
                        <div className="Product-land-360degree">
                          <h5 className="main-do-360-product">DO CX</h5>
                        </div>
                        <div className="Detect-resolve">
                          <h4>Data Harmonization 360.</h4>
                          <h4>Customer behaviour intelligence.</h4>
                          <h4>Application Insights & Reporting.</h4>
                          <h4>Next best experience engine.</h4>
                        </div>
                        <div>
                          <p className="col-md-10 depend-proDuct-financial">
                            DO CX offers a comprehensive solution, providing a
                            complete view of your customers and deep insights
                            into their needs, concerns, and areas for action.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-finance-02">
            <div className="gradient-layer-finance-01">
              <img
                src="./images/aeroplane.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-8 ai-ana-home">
                    Leveraging customer care feedback data to enhance the
                    understanding of customers for a prominent travel and
                    hospitality company.
                  </p>
                  <p className="container col-md-7 unique-home">
                    Identified emerging themes and subthemes, pinpointed the
                    root causes of customer concerns by utilizing our
                    proprietary NLP engine on unstructured customer care data.
                  </p>
                  <a
                    href="./industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="Homepage-Contact-Us-3">
                      Contact us
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                Here’s how we’ve helped our customers win at the last mile
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking">
                Utilizing customer care feedback data to aid a prominent travel
                and hospitality company in gaining a deeper understanding of
                their customers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Decreased mean time to resolution
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Through harnessing our arsenal of 30+ customer tools and
                  accelerators tailored for the financial services industry, we
                  deliver value 50% faster than off-the-shelf solutions.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <h4 className="higher-finance">
                  Higher customer retention
                  <br />
                  ecosystem
                </h4>
                <p className="col-md-12 speed-paragraph">
                  Higher customer retention signifies the success of a business
                  in maintaining loyal clientele, fostering trust, and ensuring
                  sustained revenue streams over time.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <h4 className="speed-finance">
                  Newer opportunities for <br></br>various customer <br></br>
                  touchpoints
                </h4>
                <p className="col-md-10 speed-paragraph">
                  Explore fresh opportunities across diverse customer
                  touchpoints for enhanced engagement and satisfaction.
                </p>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </section>

      <section id="video" className="landing-page-what-we-do-02">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
            {/* <div>
              <p className="ocss-2">OUR CASE STUDIES</p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">FInance</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/financial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">TRAVEL & HOSPITALITY</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/travel" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-finance">CPG</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/cpg" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/financial" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
            {/* <video className="" autoPlay loop muted>
              <source src={footerland} type="video/mp4" />
            </video> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Travel;
