import React from "react";
import "./Lifescience.css";
import Footer from "../products/Footer";
import footerland from "../../src/videos/footerland.mp4";

const Lifescience = () => {
  const handleContactButtonClick = () => {
    const cmsLink =
      "https://dataobserve.myfreshworks.com/crm/sales/web_forms/3bb315e5ced066d76d0a5422db2d9724ded4938333d17e75ec4c1192ae934cf4/form.html";

    window.location.href = cmsLink;
  };

  return (
    <>
      <section>
        <div className="Product-landing-Redirect">
          <div className="container" style={{ margin: "auto" }}>
            <div className="landing-ProudcT">
              <div className="d-flex Landing-product-padding">
                <div>
                  <a className="homenavigateclass" href="/">
                    <img
                      src="./images/footer-do-logo.png"
                      className="footer-do-Logo-product"
                      alt="do-logo"
                      width={100}
                    />
                  </a>
                </div>
                <div className="d-flex">
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <a
                    href="/industrielanding"
                    style={{ textDecoration: "none" }}
                  >
                    <h5 className="financial-Landing-02">Industries</h5>
                  </a>
                  <i
                    class="fa fa-angle-right navbar-angle-product"
                    aria-hidden="true"
                  ></i>
                  <h6 className="financial-nav-heading">Life Sciences</h6>
                </div>
              </div>

              <div>
                <button
                  className="NavBAR-Contact-Us"
                  onClick={handleContactButtonClick}
                >
                  Contact Us
                  <i
                    class="fa fa-angle-right navbar-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 1 */}
      <section className="ind-lifesub-sec-1">
        <div className="sec-01-main">
          <div className="Life-01-main">
            <video className="dop-1" autoPlay loop muted>
              <source
                src="https://storage.googleapis.com/do-website-resources/life-science.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        <div className="life-science-sub container">
          <h1 className="life-science-sec-1">LIFE SCIENCE</h1>
          <p className="sec-1-para">
            Developing data solutions in the Life Sciences sector to stimulate
            innovation.
          </p>
        </div>
      </section>

      {/* section 2 */}
      {/* <section className="percentages-sec-2">
        <div className="sec-2-main container">
          <div className="main-para-sec-2 row">
            <div className="col-md-3">
              <h1 className="percentages">42%</h1>
              <p className="sec-2-para">
                reduction in the time required for drug discovery processes
                through advanced analytics, simulation, and modeling techniques.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">30%</h1>
              <p className="sec-2-para">
                improvement in research collaboration by enabling secure data
                sharing, interoperability, and real-time collaboration
                platforms.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">15%</h1>
              <p className="sec-2-para">
                increase in positive patient outcomes by enabling personalized
                medicine approaches, predictive diagnostics, and treatment
                optimization.
              </p>
            </div>
            <div className="col-md-3">
              <h1 className="percentages">25%</h1>
              <p className="sec-2-para">
                reduction in regulatory compliance costs by automating
                compliance processes, ensuring data integrity, and facilitating
                audit trails.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="finance-bg">
          <div className="container width-increase">
            <div className="row financial-padding">
              <div className="col-md-12 d-flex ">
                <div className="col-md-3">
                  <h3 className="percentage-number">42%</h3>
                  <p className="finance-paragraph">
                    reduction in the time required for drug discovery processes
                    through advanced analytics, simulation, and modeling
                    techniques.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">30%</h3>
                  <p className="finance-paragraph">
                    improvement in research collaboration by enabling secure
                    data sharing, interoperability, and real-time collaboration
                    platforms.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">15%</h3>
                  <p className="finance-paragraph">
                    increase in positive patient outcomes by enabling
                    personalized medicine approaches, predictive diagnostics,
                    and treatment optimization.
                  </p>
                </div>
                <div className="col-md-3">
                  <h3 className="percentage-number">25%</h3>
                  <p className="finance-paragraph">
                    reduction in regulatory compliance costs by automating
                    compliance processes, ensuring data integrity, and
                    facilitating audit trails.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 3 */}
      <section>
        <div>
          <div className="container">
            <div className="row financial-padding-01">
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <h4 className="service-Finance">
                    lIFE sCIENCE <br />
                    Expertise
                  </h4>
                </div>
                <div className="col-md-6">
                  <p className="finance-craft">
                    DataObserve provides a wide range of solutions tailored to
                    the unique needs of the life science and pharmaceutical
                    industry.
                  </p>
                  <button className="Homepage-Contact-Us">
                    Request a demo
                    <i
                      class="fa fa-angle-right home-angle"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 4 */}
      <section className="life-science-exp-sec-4">
        <div className="sec-4-hero">
          <div className="why-l-s col-md-12">
            <p className="why-d-l-s-para">WHY DATAOBSERVE’S LIFE SCIENCE?</p>

            <div className="container">
              <div className="row">
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text">
                    <img
                      src="./images/bussiness-life.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      Advanced Business Intelligence.
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Integration of Advanced BI in Life Science</b>: Apply
                        analytics for drug discovery, real-time trial
                        monitoring, and optimize resources with BI.
                      </li>
                      <li className="list-sub-1">
                        <b>Precision Medicine</b>: Utilize BI for patient data
                        analysis, personalized treatment plans, accelerated drug
                        discovery, and integration into clinical decision
                        support systems.
                      </li>
                      <li className="list-sub-1">
                        <b>Regulatory Compliance & Risk Management</b>: Ensure
                        compliance through BI for efficient reporting, use
                        predictive analytics to mitigate trial risks, and
                        establish metrics for continuous improvement.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text2">
                    <img
                      src="./images/data-driven2.png"
                      className="adv-tick-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">Data-driven R&D.</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Cloud-Driven Lab Informatics</b>: Leverage
                        cloud-driven lab informatics for intricate life science
                        insights and human-centered research and development by
                        harnessing data capabilities.
                      </li>
                      <li className="list-sub-1">
                        <b>Enhanced Governance of Business Processes</b>:
                        Utilize cloud-driven lab informatics for enhanced
                        business process governance, leveraging insights from
                        cloud data to optimize operations.
                      </li>
                      <li className="list-sub-1">
                        <b>Driving Human-Centered R&D</b>: Harness cloud-driven
                        lab informatics capabilities to propel human-centered
                        research and development, gaining intricate life science
                        insights for innovative and impactful outcomes.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text2">
                    <img
                      src="./images/supply-chain2.png"
                      className="adv-supply-HOME-science"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">
                      Manufacturing and supply chain.
                    </h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Cutting-Edge Big Data Analytics Solutions</b>:
                        Implement advanced analytics in manufacturing and supply
                        chain, leveraging cutting-edge technology to process raw
                        data and derive actionable insights.
                      </li>
                      <li className="list-sub-1">
                        <b> Comprehensive Data Conversion</b>: Utilize advanced
                        analytics tools to provide comprehensive capabilities
                        for converting raw data into actionable roadmaps and
                        strategic insights.
                      </li>
                      <li className="list-sub-1">
                        <b> Enhanced Manufacturing Operations</b>: Implement big
                        data analytics for improved efficiency and strategic
                        planning, enabling data-driven decision-making in
                        manufacturing.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text">
                    <img
                      src="./images/decentral.png"
                      className="adv-decental-HOME"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">Decentralized Clinical Trials</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b> Future-Focused Clinical Trials</b>: Explore
                        innovative trial methodologies and step into the future
                        with DataObserve's life sciences cloud.
                      </li>
                      <li className="list-sub-1">
                        <b>Human-Centric Decentralized Solutions</b>: Utilize
                        DataObserve's decentralized solutions for pharmaceutical
                        tasks, enhancing clinical trial efficiency and
                        participant experience.
                      </li>
                      <li className="list-sub-1">
                        <b>Transformation in Life Sciences Cloud</b>: Experience
                        a life sciences transformation with DataObserve's clo ud
                        solutions, embracing cutting-edge tech for elevated
                        clinical trial standards.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="adv-pro-data">
                  <div className="col-md-1 adv-new-text-1">
                    <img
                      src="./images/medical2.png"
                      className="adv-supply-HOME-science"
                      alt="star"
                    />
                  </div>
                  <div className="col-md-11 adv-text-Foremost">
                    <h5 className="advanced">Medical Devices</h5>
                    <ul className="five-items">
                      <li className="list-sub-1">
                        <b>Empowering Life Science Organizations</b>:
                        DataObserve empowers life science organizations for
                        enhanced outcomes, utilizing cloud-enabled medical
                        devices for streamlined processes.
                      </li>
                      <li className="list-sub-1">
                        <b> Digitizing Pharmaceutical Labs</b>: Facilitate lab
                        digitization with DataObserve, implementing
                        cloud-enabled medical devices for modernized and
                        improved operations.
                      </li>
                      <li className="list-sub-1">
                        <b>Enhanced Outcomes Through Technology</b>: Achieve
                        enhanced outcomes using DataObserve's technology,
                        leveraging cloud-enabled medical devices for efficiency
                        and innovation in life science and pharmaceutical
                        settings.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 5 */}

      <section>
        <div className="container">
          <div className="dev-data-main">
            <p className="dev-data-sol-para-1">
              DEVELOPING DATA SOLUTIONS IN THE LIFE <br></br>SCIENCE SECTOR TO
              STIMULATE INNOVATION.
            </p>
            <p className="our-emp-para-2">
              Our emphasis lies in assisting you in charting the correct course
              of action, eliminating <br></br>uncertainty, and progressing
              confidently by integrating the strengths of AI & analytics.
            </p>
          </div>
          <div className="row box-product-landings-life">
            <div className="col-md-12 landing-Product-over-pad-life">
              <div className="products-cards-land-life">
                <h4 className="Product-land-360-life">Pharma</h4>
                <p className="col-md-7  product-elevate-solution-life">
                  Our life sciences solutions are designed to assist
                  organizations in producing improved medications and enhancing
                  operational efficiency, all while minimizing downtime.
                </p>
              </div>
              <div className="overlay-product-life">
                <div className="Product-land-360degree-life">
                  <h5 className="main-do-360-product-life">Pharma</h5>
                </div>
                <div className="Detect-resolve">
                  <h4>DRUG DISCOVERY.</h4>
                  <h4>DURG COMMERCIALIZATION.</h4>
                  <h4>OPERATIONS AND SUPPLY-CHAIN.</h4>
                  <h4>PHARAMACOVIGILANCE.</h4>
                </div>
                <div>
                  <p className="col-md-7 depend-proDuct-life">
                    Our life sciences solutions are designed to assist
                    organizations in producing improved medications and
                    enhancing operational efficiency, all while minimizing
                    downtime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container">
          <div className="row box-product-landings">
            <div className="col-md-12 landing-Product-over-pad-life">
              <div className="products-cards-land-life">
                <h4 className="Product-land-360-life">CLINICAL DEVICES</h4>
                <p className="col-md-7 product-elevate-solution-life">
                  Our life sciences solutions are designed to assist
                  organizations in producing improved medications and enhancing
                  operational efficiency, all while minimizing downtime.
                </p>
              </div>
              <div className="overlay-product-life">
                <div className="Product-land-360degree-life">
                  <h5 className="main-do-360-product-life">CLINICAL DEVICES</h5>
                </div>
                <div className="Detect-resolve">
                  <h4>CLINICAL TRIAL MANAGEMENT</h4>
                  <h4>PATIENT ENGAGEMENT.</h4>
                  <h4>REAL-WORLD EVIDENCE.</h4>
                  <h4>DIGIAL THERAPEUTICS.</h4>
                </div>
                <div>
                  <p className="col-md-7 depend-proDuct-life">
                    From pilot studies to prevention trials, discover how our
                    cloud partners and data analysis are revolutionizing
                    traditional clinical trial approaches.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section>
        <div className="container">
          <div className="row box-product-landings-life">
            <div className="col-md-12 landing-Product-over-pad-life">
              <div className="products-cards-land-life">
                <h4 className="Product-land-360-life">CLINICAL DEVICES</h4>
                <p className="col-md-7 product-elevate-solution-life">
                  From pilot studies to prevention trials, discover how our
                  cloud partners and data analysis are revolutionizing
                  traditional clinical trial approaches.
                </p>
              </div>
              <div className="overlay-product-life">
                <div className="Product-land-360degree-life">
                  <h5 className="main-do-360-product-life">CLINICAL DEVICES</h5>
                </div>
                <div className="Detect-resolve">
                  <h4>CLINICAL TRIAL MANAGEMENT</h4>
                  <h4>PATIENT ENGAGEMENT.</h4>
                  <h4>EAL-WORLD EVIDENCE.</h4>
                  <h4>DIGIAL THERAPEUTICS.</h4>
                </div>
                <div>
                  <p className="col-md-7 depend-proDuct-life">
                    From pilot studies to prevention trials, discover how our
                    cloud partners and data analysis are revolutionizing
                    traditional clinical trial approaches.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row box-product-landings-life">
            <div className="col-md-12 landing-Product-over-pad-life">
              <div className="products-cards-land-life">
                <h4 className="Product-land-360-life">MEDICAL DEVICES</h4>
                <p className="col-md-7 product-elevate-solution-life">
                  Our life sciences solutions are designed to assist
                  organizations in producing improved medications and enhancing
                  operational efficiency, all while minimizing downtime.
                </p>
              </div>
              <div className="overlay-product-life">
                <div className="Product-land-360degree-life">
                  <h5 className="main-do-360-product-life">MEDICAL DEVICES</h5>
                </div>
                <div className="Detect-resolve">
                  <h4>DIGIAL HEALTH MANAGEMENT</h4>
                  <h4>ADVANCED ANALYICS FORMED.</h4>
                  <h4>WEB APPS FOR MEDICAL DEVICE IMAGING.</h4>
                  <h4>WEARABLE IOT'S.</h4>
                </div>
                <div>
                  <p className="col-md-11 depend-proDuct-life">
                    The convergence of medical devices with the vast
                    capabilities of cloud technology and data analytics in the
                    field of life sciences has democratized diagnostics, making
                    them more accessible to patients around the world. Explore
                    the diverse impacts it has across various sectors within the
                    domain.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="overALL-GAP-Science">
        <div className="container">
          <div className="row box-product-landings-life">
            <div className="col-md-12 landing-Product-over-pad-life">
              <div className="products-cards-land-life">
                <h4 className="Product-land-360-life">GENOMICS</h4>
                <p className="col-md-7 product-elevate-solution-life">
                  Our life sciences solutions are designed to assist
                  organizations in producing improved medications and enhancing
                  operational efficiency, all while minimizing downtime.
                </p>
              </div>
              <div className="overlay-product-life">
                <div className="Product-land-360degree-life">
                  <h5 className="main-do-360-product-life">GENOMICS</h5>
                </div>
                <div className="Detect-resolve">
                  <h4>PHARMA COGENOMICS</h4>
                  <h4>TERTIARY ANALYSIS</h4>
                  <h4>CLINICAL GENOMICS</h4>
                  <h4>WMULTI-MODA & MULTI -OMNICS</h4>
                </div>
                <div>
                  <p className="col-md-11 depend-proDuct-life">
                    In broad terms, Genomics is a field that focuses on the
                    examination of DNA genomes' structure, evolution, and
                    functionality. DO, in collaboration with its partners, has
                    been at the forefront of driving innovation in genomics
                    through the following approaches.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 6 */}

      <section>
        <div className="Landing-building-image">
          <div className="industries-height-Gaming">
            <div className="middle-building">
              <img
                src="./images/life-01.png"
                className="building-Landing"
                alt="building"
              />
            </div>
          </div>

          <div className="landing-building-text">
            <div className="container">
              <div className="row">
                <div className="">
                  <p className="container col-md-8 ai-ana-home">
                    Enhancing industry standards through the integration of AI
                    and analytics.
                  </p>
                  <p className="container col-md-7 unique-home">
                    Each industry presents its unique challenges, and for
                    numerous pressing issues, there are no established
                    playbooks. Achieving solutions requires a delicate balance
                    between adhering to best practices and engaging in
                    fundamental, ground-up thinking.
                  </p>
                  <a style={{ textDecoration: "none" }}>
                    <button
                      className="Homepage-Contact-Us-3"
                      onClick={handleContactButtonClick}
                    >
                      Contact
                      <i
                        class="fa fa-angle-right home-angle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row advantage-Padding-Life">
            <div className="col-md-6">
              <h4 className="Advantage-do">
                ENHANCING INDUSTRY STANDARDS THROUGH THE INTEGRATION OF AI AND
                ANALYTICS.
              </h4>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="col-md-12 do-analytics-banking-Life">
                Each industry presents its unique challenges, and for numerous
                pressing issues, there are no established playbooks. Achieving
                solutions requires a delicate balance between adhering to best
                practices and engaging in fundamental, ground-up thinking.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <div className="container">
            <div className="row speed-margin-finance">
              <div className="col-md-6">
                <img
                  src="./images/findable.png"
                  className="findable"
                  alt="star"
                />
                <h4 className="speed-finance">FINDABLE</h4>
                <p className="col-md-10 speed-paragraph">
                  Data is characterized by comprehensive metadata & possesses a
                  distinct and enduring identifier.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="./images/findable.png"
                  className="accessable"
                  alt="star"
                />
                <h4 className="speed-finance">ACCESSIBLE</h4>
                <p className="col-md-12 speed-paragraph">
                  Data, along with its associated metadata, is comprehensible to
                  both humans and machines and can be accessed through clearly
                  defined protocols.
                </p>
              </div>
            </div>
            <div className="row speed-margin-finance-01">
              <div className="col-md-6">
                <img
                  src="./images/interpolable2.png"
                  className="findable"
                  alt="star"
                />
                <h4 className="speed-finance">INTEROPERABLE</h4>
                <p className="col-md-10 speed-paragraph">
                  Data and their accompanying metadata employ formal and
                  accessible knowledge representation methods to ensure their
                  reusability.
                </p>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5">
                <img
                  src="./images/reusable2.png"
                  className="accessable"
                  alt="star"
                />
                <h4 className="speed-finance">REUSABLE</h4>
                <p className="col-md-12 speed-paragraph">
                  With our proficiency and readiness to deliver large-scale
                  analytics solutions for both B2B and B2C businesses, coupled
                  with our track record of supporting top-tier retail and CPG
                  enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* SECTION 7 */}

      {/* SECTION 8 */}
      <section id="video" className="landing-page-what-we-do-02-finance">
        <div className="container Landing-Page-what-wedo-2">
          <div className="container">
            <h4 className="ocss-5">Our Case Studies</h4>
          </div>
          <div className="row WHAT-we-dataobserve">
            <div className="col-md-7">
              <div>
                <img
                  className="financial-build-Image"
                  src="./images/build.png"
                  alt="build"
                />
              </div>
            </div>
            <div className="col-md-5 address-Home-page">
              <h4 className="confidence-landing-Page">
                Built A Unified Augmented Analytics Decision Intelligence
                Platform
              </h4>
              <p className="Effective-landing-wedo">
                Learn how we created a self-service, predictive, insights
                platform driven by an adaptive machine learning engine which
                helps improve decision-making.
              </p>

              <a href="./expertiselanding" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  Read
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="d-flex">
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">
                MEDIA & ENTERTAINMENT
              </h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/entertain" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">GAMING</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/gamingnew" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="finanical-overlay">
            <div className="gradient-layer-finance-lastone">
              <div className="fianacial-height">
                <video className="video-finance-last" autoPlay loop muted>
                  <source src={footerland} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="Financial-Data">
              <h5 className="gaming-heading-lifescience">fINANCE</h5>
              <p className="gaming-paragraph-finance">
                Explore a purposeful career journey within a collaborative
                setting.
              </p>
              <a href="/financial" style={{ textDecoration: "none" }}>
                <button className="Homepage-Contact-Us">
                  View
                  <i
                    class="fa fa-angle-right home-angle"
                    aria-hidden="true"
                  ></i>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-color-finance">
            <a href="/industrielanding" style={{ textDecoration: "none" }}>
              <div className="video-finance-last-1 video-color-bg-angle">
                <h4 className="explore-finance-heading">Explore Industries</h4>
                <i
                  class="fa fa-angle-right home-angle-finance"
                  aria-hidden="true"
                ></i>
              </div>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Lifescience;
